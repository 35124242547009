import React from 'react';

class WhoAreWe extends React.Component {
    render() {
        return (
            <section className="bg-cover overlay overlay-darkblue overlay-20 gradient-purple">
            <div className="container position-relative text-left text-white">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center ">
                        <div className="pt-7 pt-lg-2 pb-4 text-sm-left text-center">
                            <h3 className="text-ph-blue font-nunito text-uppercase fs-13">
                                <strong>ABOUT</strong>
                            </h3>
                            <h2 className="display-5">
                                Who are we?
                            </h2>
                            <p className="lead fs-20 mt-4">
                                …and more importantly why are we on your bank statement?
                            </p>
                            <p className="fs-16">
                                Mootpay.com provides payment support services to retailers around the globe to help customers manage and validate their payments quickly and easily.
                                By entering the identifier on your bank statement you can see and manage all the payment details that we hold for you with that retailer.

                            </p>
                        </div>
                    </div>
                    <div className="d-none d-sm-block d-mg-block d-lg-block col-md-6 ">
                        <img src={require('../../assets/img/PayhelpHelp_Pattern_WHITE.png')} alt="" srcSet="" />
                    </div>
                </div>
            </div>
        </section>
        )
    }
}

export default WhoAreWe;
