import api from './Api'

export default class CustomerService
{


    async Search(merchantId, productDescriptorId, email)
    {
        return await api.post("customer/search",
        {
            emailAddress: email,
            merchantId : merchantId,
            productDescriptorId: productDescriptorId
        });
    }

    async SearchCard(model)
    {
        return await api.post("customer/search/card", model);
    }

    // Obsolete
    async Dashboard(merchantId, productDescriptorId, customerId)
    {
        return await api.post("customer/dashboard",
        {
            merchantId: merchantId,
            customerId: customerId,
            productDescriptorId: productDescriptorId
        });
    }

    async Dashboard3(customerId)
    {
        return await api.get("customer/dashboard3?customerId=" + customerId);
    }

    async CancelAccount(merchantId, productDescriptorId, customerId)
    {
        return await api.post("customer/cancelAccount",
        {
            merchantId: merchantId,
            customerId: customerId,
            productDescriptorId: productDescriptorId
        });
    }

    async ContactMerchant(merchantId, productDescriptorId, customerId)
    {
        return await api.post("customer/contactMerchant",
        {
            merchantId: merchantId,
            customerId: customerId,
            productDescriptorId: productDescriptorId
        });
    }

    async ApproveTransactions(merchantId, productDescriptorId, customerId)
    {
        return await api.post("customer/approve",
        {
            merchantId: merchantId,
            customerId: customerId,
            productDescriptorId: productDescriptorId
        });
    }

    async RequestRefund(merchantId, productDescriptorId, customerId, disputeReason, disputeComment, firstName, lastName)
    {
        return await api.post("customer/requestRefund",
        {
            merchantId: merchantId,
            customerId: customerId,
            productDescriptorId: productDescriptorId,
            disputeReason: disputeReason,
            comment: disputeComment,
            name: ((firstName ?? '') + ' ' + (lastName ?? '')).trim()
        });
    }

    async RequestRefund2(model)
    {
        return await api.post("customer/requestRefund", model);
    }

    async RequestContact(merchantId, productDescriptorId, customerId, comment, name)
    {
        return await api.post("customer/requestContact",
        {
            merchantId: merchantId,
            customerId: customerId,
            productDescriptorId: productDescriptorId,
            comment: comment,
            name: name
        });
    }

    async CaseNumber(merchantId, customerId)
    {
        return await api.get("customer/case-number?merchantId=" + merchantId + "&customerId=" + customerId);
    }

    async GetPayments(customerId)
    {
        return await api.get("customer/payments?customerId=" + customerId);
    }

    async RequestMerchant(merchantCode)
    {
        return await api.get("customer/merchant?merchantCode=" + merchantCode);
    }
    
    async CodeToDashboard(email, code)
    {
        return await api.post("customer/dashboard-link", {
            email: email,
            code: code
        });
    }

    async RequestSupport(name, email, message)
    {
        return await api.post("customer/requestSupport",
        {
            name: name,
            email: email,
            message: message
        });
    }

    async ListRequests(customerId)
    {
        return await api.get("customer/requests?customerId=" + customerId);
    }

    async ReadRequest(customerId, requestId)
    {
        return await api.get("customer/request/read?customerId=" + customerId + "&requestId=" + requestId);
    }
    
    async SendRequestMessage(requestId, content)
    {
        return await api.post("customer/request/message",
        {
            requestId: requestId,
            content: content
        });
    }

    async CloseRequest(customerId, requestId)
    {
        return await api.get("customer/request/close?customerId=" + customerId + "&requestId=" + requestId);
    }
    
    async ListDisputeReasons(customerId)
    {
        return await api.get("customer/diputeReasons?customerId=" + customerId);
    }

    async GetChargeDetails(customerId)
    {
        return await api.get("customer/charge/details?customerId=" + customerId);
    }
}
