import React from 'react';
import './RateApp.css';

class RateApp extends React.Component {
    constructor(props) {
        super(props);
        console.log("customerId", props.customerId, props);
        this.state =
        {
            rateSent:false,
            source: props.source,
            customerId: props.customerId,
            error: null,
            isLoaded: false,
            redirect: null,
            refunded: false,
            isLoading: false,
            caseNumber: props.caseNumber
        };
    }
    names = ['1', '2', '3', '4', '5'];

    sendRate(rate)
    {
        console.log("User", this.state.customerId, "rated app with ", rate, " source ",this.state.source);
        this.setState({rateSent: true});
    }

    render() {
        return (
            <div className='rateappContainer'>
                {this.state.rateSent ?
                    <p className='text-center h4 pt-2'>Thank you!</p>
                :
                 this.names.map(name => (
                    <div className='text-center radio1' key={name}>
                        <label htmlFor={'radioRate' + name}>
                            {name}
                            <br />
                            <input type='radio' name="rateApp" value={name} id={'radioRate' + name} onClick={()=>this.sendRate(name)}></input>
                        </label>
                    </div>
                ))}
                
            </div>
        )
    }
}

export default RateApp;
