import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import './NotFoundBanner.css'


function NotFoundBanner() {
    return (
        <Container fluid className="notfound-bg">
            <Row className="justify-content-center">
                <Col md={4} style={{ position: "relative", bottom: "-27vh" }}>
                    <h2>Something went wrong!</h2>
                    <p>We can't seem to find the page you are looking for.</p>
                    <p>error code: 404</p>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFoundBanner;
