import React from 'react';
import { Alert } from 'react-bootstrap'
import CustomerService from '../../service/CustomerService'
import '../../index.css'

class CodeToDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {     
            email:this.props.email,
            code:"",
            isLoading:false
        };

        this.handleChange = this.handleChange.bind(this);
        this.GoToDashboard = this.GoToDashboard.bind(this);

    }
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
          });
    }

    GoToDashboard(event) {
        this.setState({ isLoading: true });
        event.preventDefault();
        var customerService = new CustomerService();

        customerService.CodeToDashboard(this.state.email, this.state.code)
            .then((response) => {
                //console.log(response, response.data);
                window.location.href = response.data;
                this.setState({
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                    info: "We are redirecting you to your dashboard"
                });
            }).catch((err) => {
                this.setState({
                    isLoaded: true,
                    isLoading: false,
                    error: err?.response?.data?.error ?? "There was an error while searching for your account. Please try again later.",
                    info: null
                });
            });
    }

    render() {
        return (
            <div className='mainContainer'>           
                <form className="ml-lg-auto shadow-light" onSubmit={this.GoToDashboard}>
                    {this.state.error ?
                        <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                            <Alert.Heading>Oh no!</Alert.Heading>
                            <p>{this.state.error}</p>
                            <a href="/support" className="btn btn-sm btn-info">Contact support</a>
                        </Alert> : null}
                        {/* I commented belo code because I think we will return to this. */}
                    {/* <div>
                        <h4 className="mainText">
                            We have sent you an email. Click on the link or enter the code below
                        </h4>
                    </div> */}
                    <div className='row'>
                        <div className='col-sm-8'>
                            <input type="text" maxLength="4" value={this.state.code} name="code" onChange={this.handleChange} required 
                                className="form-control mt-2 emailInput" placeholder="Enter code from email" />
                        </div>
                        <div className='col-sm-4 mt-auto mb-0'>
                            <button className="btn btn-lg hover-lift-light form-control separator button" type="submit">
                                {this.state.isLoading ? 'Loading…' : 'Go'}
                            </button>
                        </div>
                    </div>
                </form>
            </div> 
        )
    }
}

export default CodeToDashboard;
