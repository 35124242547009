import React from 'react';

class PrivacyPolicy extends React.Component {


    render() {
        return (
            <section class="mt-6 fx-fade-up animation-delay-2">
                <div class="container">
                    <h1 class="text-dark font-weight-normal mt-4 text-center">
                        Privacy policy
        </h1>

                    <div class="pt-5">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th>Owner</th>
                                    <td>DPA Officer</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>Policy</td>
                                </tr>
                                <tr>
                                    <th>Audience</th>
                                    <td>Users of <a href="http://www.phhub.org">www.phhub.org</a> and <a href="http://www.paymenthelp.org">www.paymenthelp.org</a></td>
                                </tr>
                                <tr>
                                    <th>Classification</th>
                                    <td>Public</td>
                                </tr>
                                <tr>
                                    <th>Issue Date</th>
                                    <td><time datetime="2022-03">March 2022</time></td>
                                </tr>
                            </tbody>
                        </table>

                        <h1>1. Policy statement</h1>
                        <ol>
                            <li>Payment Help Ltd and the wider Payment Help Ltd (together &ldquo;<strong>we</strong>&rdquo;/ &ldquo;<strong>our</strong>&rdquo;/ &ldquo;<strong>us</strong>&rdquo;) are committed to protecting the privacy of our website users and take our responsibility regarding the security of their information very seriously.&nbsp;We will be clear and transparent about the information we are collecting and what we will do with that information.</li>

                            <li>This Privacy Policy tells you about the information we collect from you when you use our website. In collecting this information, we are acting as a Data Controller and, by law, we are required to provide you with information about us, about why and how we use your Personal Data, and about the rights you have over your Personal Data.</li>
                            <li>
                                This Policy sets out the following:
                    <ul>
                                    <li>what Personal Data we collect and process about you in connection with your relationship with us as a user of our website;</li>
                                    <li>where we obtain your Personal Data from;</li>
                                    <li>what we do with your Personal Data;</li>
                                    <li>how we store your Personal Data;</li>
                                    <li>who we transfer/disclose your Personal Data to;</li>
                                    <li>how we deal with your data protection rights; and</li>
                                    <li>how we comply with the applicable data protection laws and regulations.</li>
                                </ul>
                            </li>
                            <li>The Processing of Personal Data within the United Kingdom is regulated by the Data Protection Act 2018, which incorporates fully, the European Union&rsquo;s General Data Protection Regulation 2016/679 and is now recognised and referenced as the UK&rsquo;s General Data Protection Regulations (UK GDPR). We process all Personal Data in accordance with the UK&rsquo;s</li>
                        </ol>

                        <h1>2. Definitions</h1>
                        <dl>
                            <dt>Data Controller</dt>
                            <dd>The organisation which determines the purpose(s) and means of Processing of Personal Data (e.g. Payment Help Ltd); think of a &lsquo;data controller&rsquo; as a "data owner".</dd>

                            <dt>Data Processor</dt>
                            <dd>The organisation/individual that Processes Personal Data on behalf of the Data Controller (e.g. IT service providers).</dd>

                            <dt>Personal Data</dt>
                            <dd>A broadly defined term meaning any information relating to an individual who can be identified, directly or indirectly, from such data e.g. name, email address, IP address and mobile telephone number. Descriptions of individuals with sufficient specificity will also be considered &lsquo;personal data&rsquo;.</dd>

                            <dt>Processing/Processed</dt>
                            <dd>Any use of Personal Data e.g. storage in databases, input onto systems and applications, sharing with law enforcement agencies or creating customer accounts. The act of typing a customer&rsquo;s name into a spreadsheet is an example of &lsquo;processing&rsquo; Personal Data.</dd>
                        </dl>

                        <h1>3. Contact details</h1>
                        <ol>
                            <li>
                                You can contact us by post at the undernoted address, by email at the undernoted email address or by telephone on <a href="tel:+443333440858">+44 (0)333 344 0858</a>.
                    <br />
                    We are registered with the Information Commissioner as a Data Controller with Registration Number <code>ZA770511</code>.
                </li>
                            <li>
                                The contact details of the Company&rsquo;s Data Protection Officer (&ldquo;<strong>DPO</strong>&rdquo;) are:
                    <p>Email Address: <a href="mailto:dataprotection@paymenthelp.org">dataprotection@Paymenthelp.org</a> or</p>
                                <p>
                                    Postal Address:<br />
                        Data Protection Officer<br />
                        Payment Help Ltd.<br />
                        York House, 8-12 Salisbury Square,<br />
                        Old Hatfield,<br />
                        Hertfordshire,<br />
                        AL9 5AD
                    </p>
                            </li>
                        </ol>

                        <h1>4. What Personal Data do we collect?</h1>
                        <p>We collect Personal Data that you give us by filling in forms on our website or by corresponding with us by telephone, e-mail or otherwise. It includes information you provide if you register to use our website, use or subscribe to a service provided by us and also when you report a problem with our website. The information you give us may include your:</p>
                        <ul>
                            <li>name;</li>
                            <li>address;</li>
                            <li>e-mail address;</li>
                            <li>telephone number;</li>
                            <li>technical information (such as IP address).</li>
                        </ul>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Your information</th>
                                    <th>When?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Your:
                            <ul>
                                            <li>name (first name and surname); and</li>
                                            <li>contact details (i.e. email address, telephone number and/or postal address)</li>
                                        </ul>
                                    </td>
                                    <td>
                                        When you:
                            <ul>
                                            <li>create an account on our website;</li>
                                            <li>request information;</li>
                                            <li>take part in our competitions; or</li>
                                            <li>choose a service that we make available on our website</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Information about your transaction, including your payment card details</td>
                                    <td>When you use our service to query transactions or the status of queries.</td>
                                </tr>
                                <tr>
                                    <td>Communications you exchange with us (e.g. your emails, letters, telephone calls, or your messages on our online chat service)</td>
                                    <td>When you contact us or you are contacted by us</td>
                                </tr>
                                <tr>
                                    <td>Your posts and messages on social media directed to us</td>
                                    <td>When you interact with us on social media</td>
                                </tr>
                                <tr>
                                    <td>Your feedback</td>
                                    <td>
                                        When you:
                            <ul>
                                            <li>reply to our requests for feedback;</li>
                                            <li>use the feedback tool on our website; or</li>
                                            <li>participate in our customer surveys</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Information about how you use our website or our mobile app</td>
                                    <td>
                                        When you:
                            <ul>
                                            <li>navigate on our website; or</li>
                                            <li>use our mobile app</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Information that relates to your membership/account</td>
                                    <td>When you open an account</td>
                                </tr>
                            </tbody>
                        </table>

                        <h1>5. How do we use your Personal Data?</h1>
                        <ol>
                            <li>
                                <strong>Information you give to us:</strong>
                                <p>We may use this information to:</p>
                                <ol>
                                    <li>provide you with status updates on transactions that you have queried or any other service that we offer.</li>
                                    <li>contact you, respond to any correspondence, email or telephone call you have made to us or to provide the information you have requested from us;</li>
                                    <li>notify you about changes to our service; and</li>
                                    <li>ensure that content from our website is presented in the most effective manner for you and for your computer.</li>
                                </ol>
                            </li>

                            <li>
                                <strong>Information we collect about you:</strong>
                                <p>We may use this information:</p>
                                <ol>
                                    <li>to administer our website and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
                                    <li>to improve our website to ensure that content is presented in the most effective manner for you and for your computer;</li>
                                    <li>to allow you to participate in interactive features of our service, when you choose to do so;</li>
                                    <li>as part of our efforts to keep our website safe and secure;</li>
                                    <li>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you; and</li>
                                    <li>to make suggestions and recommendations to you and other users of our website about goods or services that may interest you or them.</li>
                                    <li>In support of any regulatory or law enforcement requests or to comply with any other legal obligation on the Company</li>
                                </ol>
                            </li>

                            <li>
                                <strong>Information we receive from other sources:</strong>
                                <p>We may combine information we receive from other sources with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).</p>
                            </li>
                            <li>We will only Process your Personal Data where we have a legal basis to do so.</li>
                            <li>
                                We may also Process your Personal Data for one or more of the following reasons:
                    <ol>
                                    <li>to comply with a legal obligation;</li>
                                    <li>where you have consented to us using your Personal Data (e.g. for marketing-related uses);</li>
                                    <li>to protect your vital interests or those of another person; or</li>
                                    <li>where it is in our legitimate interests in operating as a business (e.g. for administrative purposes).</li>
                                </ol>
                            </li>
                        </ol>

                        <h1>6. Sensitive personal information</h1>
                        <p>We ask that you do not send or disclose any sensitive personal information to us either through our website, by post, by email, by text message, through live chat or via telephone call or any other method. For clarity, &ldquo;<strong>sensitive personal information</strong>&rdquo; means information relating to racial or ethnic origin, political opinions, religious or other beliefs, health, criminal background or trade union membership.</p>

                        <h1>7. How long do we keep your Personal Data?</h1>
                        <ol>
                            <li>We will not retain your Personal Data for longer than is necessary to fulfil the purpose it is being Processed for and to comply with all legal obligations. To determine the appropriate retention period, we consider the amount, nature and sensitivity of the Personal Data, the purposes for which we Process it and whether we can achieve those purposes through other means.</li>
                            <li>We must also consider periods for which we might need to retain Personal Data to meet our legal obligations or to deal with complaints and queries.</li>
                            <li>When we no longer need your Personal Data, we will securely delete or destroy it. We will also consider if and how we can minimise over time the Personal Data that we use, and if we can anonymise your Personal Data so that it can no longer be associated with you or identify you, in which case we may use that information without further notice to you.</li>
                        </ol>

                        <h1>8. Where we store your Personal Data</h1>
                        <ol>
                            <li>The Personal Data that we collect from you will be Processed in the UK. It may also be Processed by individuals operating outside the UK who work for us or on our behalf. Such staff may be engaged in, amongst other things, the Processing of your payment details and the provision of support services. By submitting your Personal Data, you agree to this Processing. We will take all steps reasonably necessary to ensure that your Personal Data is treated securely and in accordance with the appropriate provision(s) of the GDPR.</li>
                            <li>Where we have given you (or where you have chosen) a password that enables you to access certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</li>
                            <li>We use physical, technological and administrative safeguards to protect your Personal Data against loss, misuse or alteration. All your Personal Data is stored securely and may only be accessed by Company employees with a legitimate business need to access the Personal Data.</li>
                        </ol>

                        <h1>9. Security of your Personal Data</h1>
                        <ol>
                            <li>We follow strict security procedures in the storage and disclosure of your Personal Data and in protecting it against accidental loss, destruction or damage.</li>
                            <li>We may disclose your Personal Data to trusted third parties for the purposes set out in this Privacy Policy. We require all such third parties to have appropriate technical and operational security measures in place to protect your Personal Data, in line with the GDPR.</li>
                        </ol>

                        <h1>10. Sharing of your Personal Data</h1>
                        <p>In addition to the information that we share in order to comply with our legal obligations, we may also share or disclose your Personal Data to:</p>
                        <ol>
                            <li>third parties that process Personal Data on our behalf; or</li>
                            <li>any other party with your prior consent.</li>
                        </ol>

                        <h1>11. Data Processor</h1>
                        <p>Where we are a Data Processor for your Personal Data, we will:</p>
                        <ol>
                            <li>only act on the written instructions of the Data Controller;</li>
                            <li>not use a sub-processor without the prior written authorisation of the Data Controller;</li>
                            <li>co-operate with the relevant supervisory authority (such as the Information Commissioner&rsquo;s Office in the UK);</li>
                            <li>ensure the security of its Processing;</li>
                            <li>keep records of our Processing activities; and</li>
                            <li>notify any Personal Data breaches to the Data Controller.</li>
                        </ol>

                        <h1>12. Your data protection rights</h1>
                        <ol>
                            <li>
                                In certain circumstances you have the legal right to:
                    <ul>
                                    <li><strong>Request information</strong> about whether we hold Personal Data about you and, if so, what that information is and why we are holding/using it.</li>
                                    <li><strong>Request access</strong> to your Personal Data (commonly known as a "data subject access request"). This enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully Processing it.</li>
                                    <li><strong>Request correction</strong> of the Personal Data that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected.</li>
                                    <li><strong>Request erasure</strong> of your Personal Data. This enables you to ask us to delete or remove Personal Data where there is no good reason for us continuing to Process it. You also have the right to ask us to delete or remove your Personal Data where you have exercised your right to object to Processing (see below).</li>
                                    <li><strong>Object to Processing</strong> of your Personal Data where we are relying on a legitimate interest (or that of a third party) and there is something about your particular situation which makes you want to object to Processing on this ground. You also have the right to object where we are Processing your Personal Data for direct marketing purposes.</li>
                                    <li><strong>Object to automated decision-making</strong> including profiling i.e. not to be subject of any automated decision-making by us using your Personal Data or profiling of you.</li>
                                    <li><strong>Request the restriction of Processing</strong> of your Personal Data. This enables you to ask us to suspend the Processing of your Personal Data e.g. if you want us to establish its accuracy or the reason for Processing it.</li>
                                    <li><strong>Request transfer of your Personal Data</strong> in an electronic and structured form to you or to another party (commonly known as a right to &ldquo;data portability&rdquo;). This enables you to take your Personal Data from us in an electronically useable format and transfer it to another party.</li>
                                    <li><strong>Withdraw consent.</strong> In the limited circumstances where you may have provided your consent to the Processing of your Personal Data for a specific purpose, you have the right to withdraw your consent for that specific Processing at any time. Once we have received notification that you have withdrawn your consent, we will no longer Process your Personal Data for the purpose(s) you originally agreed to, unless we have another legitimate basis for doing so in law.</li>
                                </ul>

                                <p>If you want to exercise any of these rights, then please contact our DPO at <a href="mailto:dataprotection@paymenthelp.org">dataprotection@paymenthelp.org</a> / Payment Help Ltd, York House, 8-12 Salisbury Square, Old Hatfield, Hertfordshire, United Kingdom, AL9 5AD.</p>
                            </li>
                            <li>You will not have to pay a fee to access your Personal Data (or to exercise any of the other above rights). However, we may charge a reasonable fee if your request for access is clearly unfounded or excessive. Alternatively, we may refuse to comply with the request in such circumstances.</li>
                            <li>We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights). This is another appropriate security measure to ensure that Personal Data is not disclosed to any person who has no right to receive it.</li>
                        </ol>

                        <h1>13. Direct marketing communications</h1>
                        <ol>
                            <li>We may use your Personal Data to enable us to send you post and emails with information about our services that we believe may be of interest to you. We may permit trusted partners to use your Personal Data in order to provide you with information about their goods and services. We will do this when we believe it is in our mutual legitimate interest to do so.</li>
                            <li>
                                By trusted partners we mean companies operating in the following categories:
                    <ul>
                                    <li>Payment Service Providers; and</li>
                                    <li>Banks</li>
                                </ul>
                            </li>
                            <li>You have the right to withdraw from or amend the receipt of direct marketing communications. If you would like to do this, then simply click on the Unsubscribe button on the email or let us know. If you do decide to do this, then you will miss out on news that we would like to make you aware of.</li>
                        </ol>

                        <h1>14. Opt-out</h1>
                        <p>You can also choose to opt-out at any time from receiving marketing communications by clicking on the relevant Unsubscribe link at the bottom of any marketing related email you may receive from us.</p>

                        <h1>15. Force Majeure</h1>
                        <p>In no event shall PaymentHelp Ltd.&nbsp;be responsible or liable for any failure or delay in the performance of its obligations hereunder arising out of or caused by, directly or indirectly, forces beyond its control, including, without limitation, strikes, work stoppages, accidents, acts of war or terrorism, pandemics or epidemics, civil or military disturbances, nuclear or natural catastrophes or acts of God, and interruptions, loss or malfunctions of utilities, communications or computer (software and hardware) services; it being understood that PaymentHelp Ltd.&nbsp;shall use reasonable efforts which are consistent with accepted practices to resume performance as soon as practicable under the circumstances.</p>

                        <h1>16. Cookies</h1>
                        <ol>
                            <li>A cookie is a small file of letters and numbers that we may store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</li>
                            <li>Cookies make it easier for you to log onto and use websites. Any aggregate information collected permits us to analyse traffic patterns on our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our website. By continuing to browse our website, you consent to any use by us of cookies in accordance with this cookies policy.</li>
                        </ol>

                        <h1>17. What cookies do we use?</h1>
                        <p>We may use the following cookies:</p>
                        <ol>
                            <li><strong>Strictly necessary cookies</strong>: these are required for the operation of our website e.g. cookies that enable you to log into secure areas of our website.</li>
                            <li><strong>Analytical/performance cookies</strong>: they allow us to recognise and count the number of visitors and see how visitors move around our website when they are using it. This helps us improve the way our website works by ensuring that users are finding what they are looking for easily.</li>
                            <li><strong>Functionality cookies</strong>: these are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences e.g. your choice of language or region.</li>
                            <li><strong>Targeting cookies</strong>: these record your visit to our website, the pages you have visited and the links you have followed. We may use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
                        </ol>

                        <h1>18. Changes to this Privacy Policy</h1>
                        <p>This Privacy Policy may change from time to time and any changes to it will be communicated to you by way of an e-mail or a notice on our website.</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default PrivacyPolicy;
