import React from 'react';
import { Link } from 'react-router-dom';
import CustomerService from '../service/CustomerService';
import FormatHelper from '../service/FormatHelper';
import '../index.css';

class Requests extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            customerId: props.match.params.customerId,
            info: null,
            error: null,
            name:'',
            message:'',
            requests:[]
        };
    }

    componentDidMount()
    {
        //console.log("componentDidMount");
        var customerService = new CustomerService();
        customerService.ListRequests(this.state.customerId)
            .then((resp) => {
                console.log(resp.data);
                this.setState({
                    requests: resp.data
                });
            });//.catch((err) => this.handleError(err));
    }

    requestTypeToString(type) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 0:
                // Do something for summer
                break;
            case 1:
                return 'Cancel account';
            //Do something for winter
            case 2:
                return 'Refund payment'
            //Do something for spring
            case 3:
                return 'Cancel and refund'
            case 5:
                return 'Message'
        }
    }

    requestStatusToString(status) {
        // eslint-disable-next-line default-case
        switch (status) {
            case 0:
                return 'Open';
            case 1:
                return 'Pending';
            case 2:
                return 'Resolved'
            case 3:
                return 'Closed'
        }
    }

    render() {
        return (
            <div className="bg-light">
                <section className="container mt-4">
                    <h4>Your requests</h4>
                    <p className="text-secondary font-size-sm mt-3">
                        These are the requests we have found for you to this merchant.
                    </p>
                    
                    <div style={{  overflowX: "auto" }}>
                        <table className="table table-sm" style={{ position:"relative", height: "100px", overflowY: "auto" }}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Made on</th>
                                    <th>Closed on</th>
                                    <th>Status</th>
                                    <th>Messages</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    React.Children.toArray(
                                        
                                        this.state.requests.map((request) =>
                                            <tr>
                                                <td>{this.requestTypeToString(request.type)}</td>

                                                <td><span title={request.madeAt}>{FormatHelper.JsonTimeToLocalDate(request.madeAt)}</span></td>
                                                <td><span title={request.closedAt}>{FormatHelper.JsonTimeToLocalDate(request.closedAt)}</span></td>
                                                <td>{this.requestStatusToString(request.status)}</td>
                                                <td>
                                                    <Link to={{pathname:`/Messages/${this.state.customerId}/${request.id}`}} >View</Link>
                                                    &nbsp;
                                                     {request.hasUnreadMessages ? 
                                                     <Link to={{pathname:`/Messages/${this.state.customerId}/${request.id}`}} ><img src={require('../assets/img/message-icon.png')} height="16" alt="Unread message" /></Link>
                                                      : <span></span>} 
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to home</Link>
                </section>
            </div>

        );
    }
}

export default Requests;
