import React from 'react';
import { Button, Alert } from 'react-bootstrap'
import CustomerService from '../../service/CustomerService'

class ContactMerchant extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
        this.state =
        {
            merchantId: props.match.params.merchantId ?? '',
            merchantCode: props.match.params.merchantCode ?? '',
            customerId: props.match.params.customerId ?? '',
            productDescriptorId: props.match.params.productDescriptorId ?? '',
            error: null,
            isLoaded: false,
            redirect: null,
            merchantContactEmail: '',
            merchantContactNumber: '',
            merchantContactSupportUrl: '',
            isLoading: false,
        };

        this.ContactMerchant = this.ContactMerchant.bind(this);

    }

    ContactMerchant(event) {
        event.preventDefault();
        var customerService = new CustomerService();
        this.setState({ isLoading: true });
        customerService.ContactMerchant(this.state.merchantId, this.state.productDescriptorId, this.state.customerId)
            .then((resp) => {
                this.setState({
                    isLoading: false,
                    info: "Hey we've retrieved the contact details. Here they are!",
                    merchantContactEmail: resp.data.contactEmail,
                    merchantContactNumber: resp.data.contactNumber,
                    merchantContactSupportUrl: resp.data.contactSupportUrl
                })
            }).catch((err) => {
                this.setState({ isLoading: false, error: err?.response?.data?.error ?? "There was an issue retrieving the merchants contact information, please try again later." })
            });


    }

    render() {
        return (
            <div>
                {this.state.error ?
                    <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                        <Alert.Heading>Oh no! An error occurred!</Alert.Heading>
                        <p>
                            {this.state.error}
                        </p>
                    </Alert> : null}
                <div className="card h-100 shadow-light">
                    <div className="card-body">
                        <h5 className="font-weight-normal text-dark text-decoration-none">
                            Contact
                        </h5>
                        <p className="text-secondary font-size-sm mt-3" hidden={this.state.merchantContactEmail}>
                            Click here if you would like to view the merchants customer support details.
                        </p>
                        {this.state.merchantContactEmail ?
                            <div style={{'overflow-x': 'auto'}}>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <a href={'mailto:' + this.state.merchantContactEmail}>{this.state.merchantContactEmail}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={'tel:' + this.state.merchantContactNumber}>{this.state.merchantContactNumber}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <a href={this.state.merchantContactSupportUrl} target="_blank" rel="noopener noreferrer">{this.state.merchantContactSupportUrl}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> : null}
                    </div>
                    <div className="card-bottom-info" hidden={this.state.merchantContactEmail}>
                        <form onSubmit={this.ContactMerchant}>
                            <Button className="hover-lift-light" block type="submit">{this.state.isLoading ? 'Loading…' : 'View details'}</Button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactMerchant;
