import React from 'react';
import FormatHelper from '../../service/FormatHelper';

const SingleTransactionCard = ({charges, chargeId}) => {
  return (
    React.Children.toArray(
        charges.map((ch) => (
          <>
            <div>
                <h1 className="display-5 text-white">
                        Confirm you are the cardholder
                </h1>
            </div>
            <div className='text-white mt-4'>
                <span>
                Transaction date: {FormatHelper.JsonTimeToLocalDate(ch.transactionDate)}
                </span>
                <br/>
                <span>Amount: {ch.amount}</span>
                <br/>
                <span>Seller name: {ch.sellerName}</span>
                <br/>
                <span>Card issuer: {ch.cardIssuer}</span>
            </div> 
          </>

        ))

    )
  )
}

export default SingleTransactionCard