import React from 'react';

class TermsAndConditions extends React.Component {


    render() {
        return (
            <section class="mt-6 fx-fade-up animation-delay-2">
                <div class="container">
                    <h1 class="text-dark font-weight-normal mt-4 text-center">
                        Terms and conditions
        </h1>

                    <div class="pt-3">
                        <p>These terms and conditions are the contract between you and Payment Help Ltd (&ldquo;us&rdquo;, &ldquo;we&rdquo;, etc). By visiting or using Our Websites <a href="http://www.paymenthelp.org">www.paymenthelp.org</a> and <a href="http://www.phhub.org">www.phhub.org</a> , you agree to be bound by them.</p>
                        <p>We are Payment Help Ltd, a company registered in the UK, 12588250. Our address is York House,8-12 Salisbury Square, Old Hatfield, Hertfordshire, AL95AD</p>
                        <p>You are: Anyone who uses Our Website.</p>
                        <p>Please read this agreement carefully and save it. If you do not agree with it, you should leave Our Website immediately.</p>
                        <p>It is now agreed as follows:</p>

                        <h2>1. Definitions</h2>
                        <p>In this agreement, the following words shall have the following meanings, unless the context requires otherwise:</p>
                        <dl>
                            <dt>Content</dt>
                            <dd>means the textual, visual or aural content that is encountered as part of your experience on Our Website. It may include, among other things: text, images, sounds, videos and animations. It includes content Posted by you.</dd>

                            <dt>Post</dt>
                            <dd>means display, exhibit, publish, distribute, transmit and/or disclose information, Content and/or other material on Our Website, and the phrases "Posted" and "Posting" shall be interpreted accordingly.</dd>

                            <dt>Services</dt>
                            <dd>means all of the services available from Our Website, whether free or charged.</dd>

                            <dt>Our Website</dt>
                            <dd>means any website or service designed for electronic access by mobile or fixed devices which is owned or operated by us, or any member of the Paradigm group of companies. It includes all web pages controlled by us.</dd>
                        </dl>

                        <h2>2. Our contract</h2>
                        <ol>
                            <li>These terms and conditions regulate the business relationship between you and us. By using our services or using Our Website free of charge, you agree to be bound by them.</li>
                            <li>We do not offer the Services in all countries. We may refuse to provide the Services if you live in a country we do not serve.</li>
                            <li>In entering into this contract, you have not relied on any representation or information from any source except the definition and explanation of the Services given on Our Website.</li>
                            <li>Subject to these terms and conditions, we agree to provide to you some or all of the Services described on Our Website from time to time.</li>
                            <li>Our contract with you and licence to you last for the duration of the service. Any continuation by us or by you after the completion of the service is a new contract under the terms then posted on Our Website. Your continued use of our Services after that shall be deemed acceptance by you of the changed Service, system and/or terms.</li>
                            <li>The contract between us comes into existence when you accept these terms and conditions.</li>
                            <li>If we give you free access to a Service or feature on Our Website which is normally a charged feature, and that Service or feature is usually subject to additional contractual terms, you now agree that you will abide by those terms.</li>
                            <li>We may change this agreement in any way at any time. The version applicable to your contract is the version which was posted on Our Website at the time that the contract was made.</li>
                        </ol>

                        <h2>3. Your account and personal information</h2>
                        <ol>
                            <li>When you visit Our Website, you accept responsibility for any action done by any person in your name or under your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner.</li>
                            <li>You agree that you have provided accurate, up to date, and complete information about yourself. We are not responsible for any error made as a result of such information being inaccurate.</li>
                            <li>You agree to notify us of any changes in your information immediately it occurs. If you do not do so, we may terminate your account.</li>
                        </ol>

                        <h2>4. Security of Our Website</h2>
                        <ol>
                            <li>If you violate Our Website, we reserve all our legal rights which include but are not limited to, prosecution and the seeking of financial compensatory relief and costs.</li>
                            <li>
                                You now agree that you will not, and will not allow any other person to:
                    <ol>
                                    <li>modify, copy, or cause damage or unintended effect to any portion of Our Website, or any software used within it;</li>
                                    <li>link to Our Website in any way that would cause the appearance or presentation of Our Website to be different from what would be seen by a user who accessed Our Website by typing the URL into a standard browser;</li>
                                    <li>download any part of Our Website, without our express written consent;</li>
                                    <li>collect or use any product listings, descriptions, or prices;</li>
                                    <li>collect or use any information obtained from or about Our Website or the Content except as intended by this agreement;</li>
                                    <li>aggregate, copy or duplicate in any manner any of the Content or information available from Our Website, other than as permitted by this agreement or as is reasonably necessary for your use of the Services;</li>
                                    <li>for any purpose use our name, any proprietary information (including images, text, page layout, or form) of ours or of our affiliates in any way and in particular to entice search robots to some other website;</li>
                                    <li>share with a third party any login credentials to Our Website;</li>
                                </ol>
                            </li>
                            <li>
                                Despite the above terms, we now grant a licence to you to:
                    <ol>
                                    <li>create a hyperlink to Our Website for the purpose of promoting an interest common to both parties. You can do this without specific permission. This licence is conditional upon your not portraying us or any product or service in a false, misleading, derogatory, or otherwise offensive manner. You may not use any logo or other proprietary graphic or trademark of ours as part of the link without our express written consent.</li>
                                    <li>you may copy the text of any page for your personal use in connection with the purpose of Our Website or a Service we provide.</li>
                                </ol>
                            </li>
                        </ol>

                        <h2>5. Disclaimers</h2>
                        <ol>
                            <li>The law differs from one country to another. This paragraph applies so far as the applicable law allows.</li>
                            <li>All implied conditions, warranties and terms are excluded from this agreement. If in any jurisdiction an implied condition, warrant or term cannot be excluded, then this sub paragraph shall be deemed to be reduced in effect, only to the extent necessary to release that specific condition, warranty or term.</li>
                            <li>
                                The <a href="https://phub.org">phhub.org</a> Website and Payment Help Ltd Services are provided &ldquo;as is&rdquo;. We make no representation or warranty that the Services will be:
                    <ol>
                                    <li>useful to you;</li>
                                    <li>of satisfactory quality;</li>
                                    <li>fit for a particular purpose;</li>
                                    <li>available or accessible, without interruption, or without error;</li>
                                </ol>
                            </li>
                            <li>Neither use of the Services nor the exercise of any right granted under this agreement will infringe any other intellectual property or other rights of any other person.</li>
                            <li>Our Website contains links to other Internet websites. We have neither power nor control over any such web site. You acknowledge and agree that we shall not be liable in any way for the Content of any such linked website, nor for any loss or damage arising from your use of any such website.</li>
                            <li>We are not liable in any circumstances for special, indirect or consequential damages or any damages whatsoever resulting from loss of use, loss of data or loss of revenues or profits, whether in an action of contract, negligence or otherwise, arising out of or in connection with your use of Our Website.</li>
                            <li>We claim no expert knowledge in any subject. We disclaim any obligation or liability to you arising directly or indirectly from information you take from Our Website or receive directly from a third party as a result of an introduction via Our Website.</li>
                        </ol>

                        <h2>6. Duration and termination</h2>
                        <ol>
                            <li>We may terminate this agreement at any time, for any reason, with immediate effect by sending you notice to that effect by post or e-mail.</li>
                        </ol>

                        <h2>7. Storage of data</h2>
                        <ol>
                            <li>We assume no responsibility for the deletion or failure to store or deliver email or other messages.</li>
                            <li>We may, from time to time, set a limit on the number of messages you may send, store, or receive through the Service. We may delete messages in excess of that limit. We shall give you notice of any change to your limit, except in an emergency.</li>
                            <li>You accept that we cannot be liable to you for any such deletion or failure to deliver to you.</li>
                        </ol>

                        <h2>8. Interruption to Services</h2>
                        <ol>
                            <li>If it is necessary for us to interrupt the Services, we will give you reasonable notice where this is possible and when we think the down time is such as to justify telling you.</li>
                            <li>You acknowledge that the Services may also be interrupted for many reasons beyond our control.</li>
                            <li>You agree that we are not liable to you for any loss, foreseeable or not, arising from any interruption to the Services.</li>
                        </ol>

                        <h2>9. Limitation of liability</h2>
                        <ol>
                            <li>Our total liability to you, for any one event or series of related events, and whether in contract, tort, negligence, breach of statutory duty or otherwise, shall be &pound;0 in aggregate.</li>
                            <li>
                                Neither party shall be liable to the other in any possible way, for any loss or expense which is:
                    <ol>
                                    <li>indirect or consequential loss; or</li>
                                    <li>economic loss or other loss of turnover, profits, business or goodwill.</li>
                                </ol>
                            </li>
                            <li>This paragraph (and any other paragraph which excludes or restricts our liability) applies to our directors, officers, employees, subcontractors, agents and affiliated companies (who may enforce this clause under the Contracts (Rights of Third Parties) Act as well as to us.</li>
                            <li>Nothing in this agreement excludes liability for death or personal injury or for a party's fraud.</li>
                        </ol>

                        <h2>10. Indemnity</h2>
                        <p>You agree to indemnify us against any loss, damage, liability, and/or additional costs (including those of legal counsel or consultants), suffered by us at any time and arising out of:</p>
                        <ol>
                            <li>any act, neglect or default of yours in connection with this agreement or your use of the Services;</li>
                            <li>your breach of this agreement;</li>
                            <li>your failure to comply with any law;</li>
                            <li>any act, neglect or default by any agent, employee, licensee or customer of yours;</li>
                            <li>a contractual claim arising from your use of the Services.</li>
                        </ol>

                        <h2>11. Miscellaneous matters</h2>
                        <ol>
                            <li>If any term or provision of this agreement is at any time held by any jurisdiction to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.</li>
                            <li>The rights and obligations of the parties set out in this agreement shall pass to any permitted successor in title.</li>
                            <li>
                                If you are in breach of any term of this agreement, we may:
                    <ol>
                                    <li>terminate your account and refuse access to Our Website;</li>
                                    <li>remove or edit Content, or cancel any order at our discretion;</li>
                                    <li>issue a claim in any court.</li>
                                </ol>
                            </li>
                            <li>Any obligation in this agreement intended to continue to have effect after termination or completion shall so continue.</li>
                            <li>No failure or delay by any party to exercise any right, power or remedy will operate as a waiver of it nor indicate any intention to reduce that or any other right in the future.</li>
                            <li>
                                Any communication to be served on either of the parties by the other shall be delivered by hand or sent by first class post or recorded delivery or by e-mail.
                    <br />
                    It shall be deemed to have been delivered:
                    <ul>
                                    <li>if delivered by hand: on the day of delivery;</li>
                                    <li>if sent by post to the correct address: within 72 hours of posting.</li>
                                </ul>
                            </li>
                            <li>In the event of a dispute between the parties to this agreement, then they undertake to attempt to settle the dispute by engaging in good faith with the other in a process of mediation before commencing arbitration or litigation.</li>
                            <li>This agreement does not give any right to any third party under the Contracts (Rights of Third Parties) Act 1999 or otherwise, except that any provision in this agreement which excludes or restricts the liability of our directors, officers, employees, subcontractors, agents and affiliated companies, may be enforced under that Act.</li>
                            <li>Neither party shall be liable for any failure or delay in performance of this agreement which is caused by circumstances beyond its reasonable control, including any labour dispute between a party and its employees.</li>
                        </ol>

                        <h2>12. Laws</h2>
                        <ol>
                            <li>The validity, construction and performance of this agreement shall be governed by the laws of England and Wales and you agree that any dispute arising from it shall be litigated only in that country.</li>
                        </ol>

                        <h2>13. Force Majeure</h2>
                        <ol>
                            <li>In no event shall Payment Help Ltd be responsible or liable for any failure or delay in the performance of its obligations hereunder arising out of or caused by, directly or indirectly, forces beyond its control, including, without limitation, strikes, work stoppages, accidents, acts of war or terrorism, pandemics or epidemics, civil or military disturbances, nuclear or natural catastrophes or acts of God, and interruptions, loss or malfunctions of utilities, communications or computer (software and hardware)</li>
                        </ol>
                    </div>
                </div>
            </section>
        );
    }
}

export default TermsAndConditions;
