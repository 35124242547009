import React from 'react';
import CustomerService from '../service/CustomerService';
import { Alert } from 'react-bootstrap';

class Dispute extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            // merchantId: props.match.params.merchantId,
            // merchantCode: props.match.params.merchantCode,
            customerId: props.match.params.customerId,
            //productDescriptorId: props.match.params.productDescriptorId,
            disputeReason: '',
            disputeComment: '',
            firstName: '',
            lastName: '',
            error: null,
            isLoaded: false,
            redirect: null,
            refunded: false,
            isLoading: false,
            caseNumber: props.caseNumber,
            options: [],
            info: null
        };


        this.handleDisputeReasonChange = this.handleDisputeReasonChange.bind(this);
        this.handleDisputeCommentChange = this.handleDisputeCommentChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);

        this.RequestRefund = this.RequestRefund.bind(this);

        var customerService = new CustomerService();
            customerService.ListDisputeReasons(this.state.customerId)
                .then((resp) => {

                    this.setState({ options: resp.data });
                }).catch((err) => {
                    this.setState({
                        error: err?.response?.data?.error ?? "There was an error while loading the page. Please try again later."
                    });
                });
    }

    handleDisputeReasonChange(event) {
        this.setState({ disputeReason: event.target.value });
    }

    handleDisputeCommentChange(event) {
        this.setState({ disputeComment: event.target.value });
    }

    handleFirstNameChange(event) {
        this.setState({ firstName: event.target.value });
    }

    handleLastNameChange(event) {
        this.setState({ lastName: event.target.value });
    }

    goBack(){
        window.history.back();
    }

    RequestRefund(event) {
        event.preventDefault();
        var customerService = new CustomerService();

        this.setState({ isLoading: true });

        customerService.RequestRefund(this.state.merchantId, this.state.productDescriptorId, this.state.customerId, this.state.disputeReason, this.state.disputeComment, this.state.firstName, this.state.lastName)
            .then((resp) => {
                console.log(resp.data)
                this.setState({ isLoading: false, refunded: true, info: "A refund has been requested.", error: null });
                //window.history.back();
            }).catch((err) => {
                this.setState({ isLoading: false, error: err?.response?.data?.error, info: null });
            });

    }

    render() {
        return (
            <div>
                <section className="gradient-purple overflow-hidden ">
                    <div className="container py-6">
                        <h1 className="fs-40 text-white">
                            Dispute Payment
                        </h1>
                        {this.state.info ?
                            <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                                <Alert.Heading>Success!</Alert.Heading>
                                <p>
                                    {this.state.info}
                                </p>
                                <button className="btn btn-success" onClick={() => window.history.back()}>Done</button>
                            </Alert> : null}

                        {this.state.error ?
                            <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                                <p>
                                    {this.state.error}
                                </p>
                            </Alert> : null}
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center pr-lg-5">
                                <div className="text-white">
                                    <p className="mt-4 fs-20">
                                        MootPay understands that sometimes things go wrong when you shop online. That's why we're the worlds no.1 dispute resolution service. 
                                    </p>
                                    <p className=" fs-20">
                                        Banks can take months to resolve disputes, and you have no visibility over the process. MootPay puts you in control and resolves 98% of all disputes within 24 hours.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 p-lg-0 mt-lg-0">
                                <form onSubmit={this.RequestRefund}>
                                    <div className="row mt-30 ">
                                        <div className="col-md-6 mt-2">
                                            <input type="text" maxLength="50" className="form-control control-lg border-0" placeholder="First name" value={this.state.firstName} onChange={this.handleFirstNameChange} />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <input type="text" maxLength="50" className="form-control control-lg border-0" placeholder="Last name" value={this.state.lastName} onChange={this.handleLastNameChange} />
                                        </div>
                                    </div>

                                    <div className="row mt-2 mt-lg-4">
                                        <div className="col-md-12">
                                            <select className="custom-select" required value={this.state.disputeReason} onChange={this.handleDisputeReasonChange} >
                                                <option disabled value="">What is your reason?</option>
                                                { this.state.options.map((option) => (
                                                    <option value={option.label}>{option.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row mt-2 mt-lg-4">
                                        <div className="col-md-12">
                                            <textarea minLength="30" maxLength="500" required value={this.state.disputeComment} onChange={this.handleDisputeCommentChange} className="form-control pt-4" placeholder="Please provide more information on your reason for disputing."></textarea>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mt-lg-4">
                                        <div className="col-md-6">
                                            <p className="text-white text-right">
                                                <button type="submit" className="btn btn-darkblue btn-block" disabled={this.state.refunded}>Dispute payment</button>
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <p className="text-white text-right">
                                                <button className="btn btn-primary btn-block" onClick={() => window.history.back()}>Back to home</button>
                                            </p>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        );
    }
}

export default Dispute;
