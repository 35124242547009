

export default class FormatHelper
{
    static JsonTimeToLocalTime(dateStr)
    {
        if(!dateStr)
            return "";
        var d = new Date(dateStr);
        var timeOffsetInHours = null;
        if (timeOffsetInHours == null) {
            timeOffsetInHours = (new Date().getTimezoneOffset()/60) * (-1);
        }
        d.setHours(d.getHours() + timeOffsetInHours);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
    }

    static JsonTimeToLocalDate(dateStr)
    {
        if(!dateStr)
            return "";
        var d = new Date(dateStr);
        var timeOffsetInHours = null;
        if (timeOffsetInHours == null) {
            timeOffsetInHours = (new Date().getTimezoneOffset()/60) * (-1);
        }
        d.setHours(d.getHours() + timeOffsetInHours);
        return d.toLocaleDateString();
    }
    
    static formatCurr = (amount, curr) =>
    {
        if(!curr)
            return amount;
        
        if(curr.toUpperCase() === "GBP")
            return "£"+ amount;

        if(curr.toUpperCase() === "USD")
            return "$"+ amount;

            return amount + " " + curr.toUpperCase();
    }


}