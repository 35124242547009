import React from 'react';
import CustomerService from '../service/CustomerService';
import { Alert } from 'react-bootstrap'
import CancelAccount from '../components/CancelAccount/CancelAccount';
import ContactMerchant from '../components/ContactMerchant/ContactMerchant'
import Refund from '../components/Refund/Refund'
import Payments from '../components/Payments/Payments';
import ApproveTransactions from '../components/ApproveTransactions/ApproveTransactions';
import FormatHelper from '../service/FormatHelper';

// The oldest one, left for backward compatibility
class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            merchantId: props.match.params.merchantId,
            merchantCode: props.match.params.merchantCode,
            merchantDescriptorId: props.match.params.merchantDescriptorId,
            customerId: props.match.params.customerId,
            customerRequests: [],
            error: null,
            isLoaded: false,
            redirect: null,
            cancelAccountEnabled: false,
            ContactEnabled: false,
            RefundEnabled: false,
            RefundShowCommentBox: false,
            RefundShowReasonsList: false,
            ContactEmail: '',
            ContactNumber: '',
            ContactSupportUrl: '',
            caseNumber: ''
        };

        // TODO: Get merchant data and page settings
        var customerService = new CustomerService();
        customerService.Dashboard(this.state.merchantId, this.state.merchantDescriptorId, this.state.customerId)
            .then((dashboardResp) => {
                console.log(dashboardResp.data);
                this.setState({
                    cancelAccountEnabled: dashboardResp.data.cancelAccountEnabled,
                    contactEnabled: dashboardResp.data.contactEnabled,
                    refundEnabled: dashboardResp.data.refundEnabled,
                    refundShowCommentBox: dashboardResp.data.refundShowCommentBox,
                    refundShowReasonsList: dashboardResp.data.refundShowReasonsList,
                    contactEmail: dashboardResp.data.contactEmail,
                    contactNumber: dashboardResp.data.contactNumber,
                    contactSupportUrl: dashboardResp.data.contactSupportUrl,
                    merchantName: dashboardResp.data.companyName,
                    dashboardAvailable: true,
                    isLoaded: true,
                    customerRequests: dashboardResp.data.customerRequests,
                    productLogo: dashboardResp.data.productLogo,
                    productName: dashboardResp.data.productName,
                    productInfo: dashboardResp.data.productInfo,

                });
            }).catch((err) => this.handleError(err));


        customerService.CaseNumber(this.state.merchantId, this.state.customerId)
            .then(caseNoResp => {
                this.setState({
                    caseNumber: caseNoResp.data
                });
            }).catch((err) => {
                this.setState({ isLoading: false, error: err?.response?.data?.error ?? "There was an issue retrieving the merchants contact information, please try again later." })
            });
    }

    handleError(err) {
        this.setState({
            isLoaded: true,
            error: err?.response?.data?.error
        });
    }

    requestTypeToString(type) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 0:
                // Do something for summer
                break;
            case 1:
                return 'Cancel account';
            //Do something for winter
            case 2:
                return 'Refund payment'
            //Do something for spring
            case 3:
                return 'Cancel and refund'
        }
    }

    requestStatusToString(status) {
        // eslint-disable-next-line default-case
        switch (status) {
            case 0:
                // Do something for summer
                break;
            case 1:
                return 'Pending';
            //Do something for winter
            case 2:
                return 'Resolved'
            //Do something for spring
            case 3:
                return 'Closed'
        }
    }


    // TODO: Create components & render appropriately.
    render() {
        if (this.state.isLoaded) {
            return (
                <div className="bg-light">
                    <section className="container p-5">
                        <div className="row">
                            <div className="col-lg-12 d-flex pr-lg-4">

                                {this.state.dashboardAvailable ?
                                    <div className="col-lg-12">
                                        {this.state.info ?
                                            <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                                                <Alert.Heading>Success!</Alert.Heading>
                                                <p>
                                                    {this.state.info}
                                                </p>
                                            </Alert> : null}
                                        <h3>Your case number is: {this.state.caseNumber}</h3>
                                        <p>Please use this when talking to {this.state.merchantName ? <span>{this.state.merchantName}</span> : <span>the merchant</span>} so they can easily find you.</p>

                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <img src={this.state.productLogo} className="img-fluid" alt={this.state.productName} />
                                            </div>
                                            <div className='col-md-8'>
                                                <h3>{this.state.productName}</h3>

                                                <span className=''><small>{this.state.productInfo} </small></span>
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <h3>Transactions</h3>
                                            <div className="row">
                                                <div className="col-lg-12 p-2">
                                                <Payments customerId={this.state.customerId} />
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.cancelAccountEnabled || this.state.contactEnabled || this.state.refundEnabled
                                            ?
                                            <div className="mt-5">
                                                <h3>Actions</h3>
                                                <div className="row">
                                                    <div className="col-md-8 col-lg-6 p-2">
                                                        <ApproveTransactions match={this.props.match} />
                                                    </div>

                                                    {this.state.contactEnabled ?
                                                        <div className="col-md-8 col-lg-6 p-2">
                                                            <ContactMerchant match={this.props.match} />
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="row">
                                                    {this.state.cancelAccountEnabled ?
                                                        <div className="col-md-8 col-lg-6 p-2">
                                                            <CancelAccount match={this.props.match} />
                                                        </div>
                                                        : null}
                                                    {this.state.refundEnabled ?
                                                        <div className="col-md-8 col-lg-6 p-2">
                                                            <Refund refundShowCommentBox={this.state.refundShowCommentBox} refundShowReasonsList={this.state.refundShowReasonsList} match={this.props.match} />
                                                        </div>
                                                        : null}
                                                </div>

                                            </div> : null}

                                        {this.state.customerRequests.length > 0 ?
                                            <div className="mt-5">
                                                <h3>Requests</h3>
                                                <div className="row">
                                                    <div className="col-lg-12 p-2">
                                                        <div className="card h-100 shadow-light">
                                                            <div className="card-body">
                                                                <h5 className="font-weight-normal text-dark text-decoration-none">
                                                                    Requests
                                                                </h5>
                                                                <p className="text-secondary font-size-sm mt-3">
                                                                    These are the requests we have found for you to this merchant.
                                                                </p>
                                                            </div>

                                                            <div className="card-bottom-info">
                                                                <div style={{  overflowX: "auto" }}>
                                                                    <table className="table table-sm" style={{ position:"relative", height: "100px", overflowY: "auto" }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Type</th>
                                                                                <th>Made on</th>
                                                                                <th>Closed on</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                React.Children.toArray(
                                                                                    this.state.customerRequests.map((request) =>
                                                                                        <tr>
                                                                                            <td>{this.requestTypeToString(request.type)}</td>

                                                                                            <td><span title={request.madeAt}>{FormatHelper.JsonTimeToLocalDate(request.madeAt)}</span></td>
                                                                                            <td><span title={request.closedAt}>{FormatHelper.JsonTimeToLocalDate(request.closedAt)}</span></td>
                                                                                            <td>{this.requestStatusToString(request.status)}</td>
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            : null}
                                    </div>
                                    : <div>
                                        <h2>Under Construction</h2>
                                        <p>Sorry, this page is currently unavailable. Please try again later, or contact the merchant directly.</p>
                                        <br />
                                        <ContactMerchant match={this.props.match} />
                                    </div>}
                            </div>
                        </div>
                    </section>
                </div>

            );
        }
        else {
            return (
                // TODO: Add loading icon
                <div></div>
            )
        }

    }
}

export default Dashboard;


