import React from 'react';

function Footer() {
    return (
      <footer className="footerMoot footer-dark bg-darkblue py-md-3 py-sm-1 fixed-bottomNope">
      <div className="container">
        <div className='pb-6'>
          <div className="float-left">
            <img src={require('../../assets/img/mootpaylogo_702.png')} srcSet="" height="42"  alt="MootPay" />
          </div>
          <div className="float-right">
            <a href="https://panel.mootpay.com/Home/Privacy" className="footer-link text-decoration-none mb-md-1 mb-sm-0 d-block">
              Privacy Policy
            </a>
            <a href="https://panel.mootpay.com/Home/Cookies" className="footer-link text-decoration-none mb-md-1 mb-sm-0 d-block">
              Cookie Policy
            </a>
            <a href="https://panel.mootpay.com/Home/Terms" className="footer-link text-decoration-none mb-md-1 mb-sm-0 d-block">
              Terms &amp; conditions
            </a>
          </div>

        </div>
        <div>
            <div className="text-center footer-text mt-1">
              © 2020-2022 MootPay | Registered in England and Wales with company number: 14022501
            </div>
        </div>
      </div>
    </footer>
    )
}

export default Footer;







