import React from 'react';
import CustomerService from '../service/CustomerService';
import Payments from '../components/Payments/Payments';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

class Dispute2 extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            customerId: props.match.params.customerId,
            selectedPayments:[],
            error:null,
            page:0,
            charge:{},
            rows:[]
        };

        this.RequestRefund = this.RequestRefund.bind(this);
        this.HandleNextPage = this.HandleNextPage.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        
    }
    intervalHandle=null;

    componentDidMount()
    {
        var customerService = new CustomerService();
        customerService.ListDisputeReasons(this.state.customerId)
            .then((resp) => {

                this.setState({ options: resp.data });
            }).catch((err) => {
                this.setState({
                    error: err?.response?.data?.error ?? "There was an error while loading the page. Please try again later."
                });
            });

        customerService.GetChargeDetails(this.state.customerId)
            .then((resp) => {

                this.setState({ charge: resp.data });

                var chargeData = [];
                chargeData.push({label:"Your card issuer", val:resp.data.cardIssuer });
                chargeData.push({label:"Country of origin", val:resp.data.cardCountry });
                chargeData.push({label:"Reported lost/stolen", val:"No" });
                chargeData.push({label:"Payment processor", val:resp.data.paymentProcessor });
                chargeData.push({label:"Card CVC check", val:resp.data.cvcCheck });
                chargeData.push({label:"Address verification", val:resp.data.addressVerification });
                chargeData.push({label:"3D Secure", val:resp.data.threeDSecure });
                if(resp.data.deviceUsedToPay)
                    chargeData.push({label:"Device used to pay", val:resp.data.deviceUsedToPay });
                if(resp.data.deviceIp)
                    chargeData.push({label:"Device IP", val:resp.data.deviceIp });
                if(resp.data.deviceLat && resp.data.deviceLng)
                    chargeData.push({label:"Location", val:resp.data.deviceLat + ", " + resp.data.deviceLng  });
                    
                this.setState({ chargeData: chargeData });

            }).catch((err) => {
                this.setState({
                    error: err?.response?.data?.error ?? "There was an error while loading the page. Please try again later."
                });
            });
    }

    onInputChange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
    }

    HandleNextPage(param){

        if(this.state.page === 0 && this.state.selectedPayments.length === 0)
        {
            this.setState({
                error: "Select at least one payment"
            });
            return;
        }

        var newPage = this.state.page + 1;

        if(this.state.page === 1 && param)
        {
            console.log('disputeReasonId', param);
            this.setState({disputeReasonId: param})
            const DEFAULT_REASON_ID = 1;

            if(param !== DEFAULT_REASON_ID)
            {
                newPage = 5; // jum to default short flow
                console.log('param = ', param);
            }
        }
        
        console.log('newPage = ', newPage);
        this.setState({ page: newPage });
        



        if(newPage === 2)
        {
            var rows = this.state.rows;
            var i = 0;
            this.intervalHandle = setInterval(()=>{
                
                rows.push(this.state.chargeData[i]);
                i++;
                this.setState({rows:rows});

                if(i >= this.state.chargeData.length)
                    clearInterval(this.intervalHandle);
            }, 900)
        }
    }

    RequestRefund(event) {
        event.preventDefault();
        var customerService = new CustomerService();

        this.setState({ isLoading: true });

        customerService.RequestRefund2({
            customerId: this.state.customerId,
            selectedPayments: this.state.selectedPayments,
            disputeReasonId: this.state.disputeReasonId,
            form : {
                yourname : this.state.yourname,
                whomadepayment: this.state.whomadepayment,
                otherfraudulent: this.state.otherfraudulent,
                otherinfo: this.state.otherinfo
            }
            //comment: disputeComment,
            //name: ((firstName ?? '') + ' ' + (lastName ?? '')).trim()
        }).then((resp) => {
                console.log(resp.data)
                this.setState({ isLoading: false, refunded: true, info: "A refund has been requested.", error: null });
                this.HandleNextPage();
        }).catch((err) => {
            this.setState({ isLoading: false, error: err?.response?.data?.error, info: null });
        });

    }

    onPaymentSelect = (paymentsArrays) => {
        console.log('onPaymentSelect', paymentsArrays);
        this.setState({selectedPayments: paymentsArrays});
    }

    render() {
        return (
            <div className="bg-light">
            {this.state.page === 0 ? 
                <section className="container pt-3 text-center">
                    
                    <h2 className="my-2">
                        File a dispute
                    </h2>
                    {this.state.error ?
                        <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                            <span>{this.state.error}</span>
                        </Alert> : null}
                    <p>We know things sometimes go wrong when shopping online. That's why thousands of sellers &amp; banks trust MootPay to resolve payment disputes.</p>
                    <h3>Which payments are you disputing?</h3>
                    <p>Please note that Visa &amp; Mastercard rules only allow payments processed within the last 120 days to be disputed:</p>

                    <Payments customerId={this.state.customerId} select={true} onPaymentSelect={this.onPaymentSelect} />

                    <button type='button' className="btn btn-darkblue btn-block" onClick={this.HandleNextPage}>Continue</button>
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to home</Link>
                </section> : null
            }

            {this.state.page === 1 ? 
                <section className="container pt-3 text-center">
                    
                    <h2 className="my-2">
                        Tell us what best describes your problem 
                    </h2>
                    <ul className="list-group">
                    {
                        React.Children.toArray(
                            this.state.options.map((option) =>
                            <button type='button' className="list-group-item list-group-item-action d-flex justify-content-between align-items-center" onClick={() => this.HandleNextPage(option.id)} >{option.label} <i className="bi-caret-right-fill"></i></button>
                            )
                        )
                    }
                    </ul>
                    
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to home</Link>
                </section> : null
            }

            {this.state.page === 2 ? 
                <section className="container pt-3 text-center">
                    
                    <h2 className="my-2">
                        Please wait while we request more transaction data
                    </h2>
                    <ul className="list-group">
                    {
                        React.Children.toArray(
                            this.state.rows.map((row) =>
                            <div className="list-group-item d-flex justify-content-between " ><span>{row.label}</span><span className='text-uppercase font-weight-bold'>{row.val}</span></div>
                            )
                        )
                    }
                    </ul>
                    <p>Do you wish to continue with the payment dispute?</p>
                    <button type='button' className="btn btn-darkblue btn-block" onClick={this.HandleNextPage}>Continue</button>
                    <p>Alternatively, cancel your account to prevent further payments</p>
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Cancel my account</Link>
                </section> : null
            }
            {this.state.page === 3 ? 
                <section className="container pt-3 text-center">
                    {this.state.error ?
                        <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                            <span>{this.state.error}</span>
                        </Alert> : null}
                    <h2 className="my-2">
                        Payment Dispute form
                    </h2>
                    <form onSubmit={this.RequestContact}  className="row mt-30">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Your Name</label>
                                <input type="text" className="form-control" name="yourname" value={this.state.yourname} onChange={this.onInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Do you know who made the payment? For example, a family member</label>
                                <input type="text" className="form-control" name='whomadepayment' value={this.state.whomadepayment} onChange={this.onInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Do you have any other fraudulent payments on your statement</label>
                                <input type="text" className="form-control" name='otherfraudulent' value={this.state.otherfraudulent} onChange={this.onInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Please provide us with any further information that can help your dispute</label>
                                <textarea  className="form-control" name='otherinfo' value={this.state.otherinfo} onChange={this.onInputChange} />
                            </div>
                            <div className="mt-4">
                                <button type='button' className="btn btn-darkblue btn-block" onClick={this.RequestRefund}>DISPUTE PAYMENT</button>
                            </div>
                            
                        </div>
                    </form>
                    
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to home</Link>
                </section> : null
            }
            {this.state.page === 4 ? 
                <section className="container pt-3 text-center">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <h2 className="my-2">
                        You’re dispute has now been submitted.
                    </h2>
                    
                    {/* Youtube video
                    <iframe  src="https://www.youtube.com/embed/dQw4w9WgXcQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    */}
                    
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to home</Link>
                </section> : null
            }
            {this.state.page === 5 ? 
                <section className="container pt-3 text-center">
                    {this.state.error ?
                        <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                            <span>{this.state.error}</span>
                        </Alert> : null}
                    <h2 className="my-2">
                        Tell us about your complaint
                    </h2>
                    <form onSubmit={this.RequestContact}  className="row mt-30">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Your Name</label>
                                <input type="text" className="form-control" name="yourname" value={this.state.yourname} onChange={this.onInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Please provide as much information about your complaint as possible as it will help your case.</label>
                                <textarea  className="form-control" name='otherinfo' value={this.state.otherinfo} onChange={this.onInputChange} />
                            </div>
                            <div className="mt-4">
                                <button type='button' className="btn btn-darkblue btn-block" onClick={this.RequestRefund}>DISPUTE PAYMENT</button>
                            </div>
                            
                        </div>
                    </form>
                    
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to home</Link>
                </section> : null
            }
            {this.state.page === 6 ? 
                <section className="container pt-3 text-center">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <h2 className="my-2">
                        You’re dispute has now been submitted.
                    </h2>

                    {/* Youtube video
                    <iframe  src="https://www.youtube.com/embed/dQw4w9WgXcQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    */}
                    
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    
                    <Link to={{pathname:`/panel/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to home</Link>
                </section> : null
            }
            </div>

        );
    }
}

export default Dispute2;
