import React from 'react';
import CustomerService from '../service/CustomerService';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FormatHelper from '../service/FormatHelper';
import '../index.css';

class Messages extends React.Component {
    constructor(props) {
        super(props);
        //console.log('merchantName', this.state.merchantName);
        this.state =
        {
            
            requestId: props.match.params.requestId,
            customerId: props.match.params.customerId,
            request:{messages:[]},
            //merchantName:this.props.location.state.merchantName,
            info: null,
            error: null,
            name:'',
            message:'',
            isRequestOpen: true,
            requestMessage: '',
            closedRequestMessage: ''
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.RequestContact = this.RequestContact.bind(this);
        this.closeRequest = this.closeRequest.bind(this);
    }
    componentDidMount()
    {
        console.log("componentDidMount", this.state.customerId, this.state.requestId);
        var customerService = new CustomerService();
        customerService.ReadRequest(this.state.customerId, this.state.requestId)
            .then((resp) => {
                console.log(resp.data);
                this.setState({
                    request: resp.data
                });
            });
    }

    RequestContact(event) {
        event.preventDefault();
        var customerService = new CustomerService();

        //this.setState({ isLoading: true });

        customerService.SendRequestMessage(this.state.requestId, this.state.message)
            .then((resp) => {
               
                //this.setState({ isLoading: false, refunded: true, info: "Your message has been sent successfully", error: null });
                this.setState({ message: ''});
                
                customerService.ReadRequest(this.state.customerId, this.state.requestId)
                    .then((resp) => {
                        console.log(resp.data);
                        this.setState({
                            request: resp.data
                        });
            });//.catch((err) => this.handleError(err));
                
            }).catch((err) => {
                console.log("err", err);

                var errMsg = err?.response?.data?.error;
                if(!errMsg)
                    errMsg = "Something went wrong. Please try again later."
                this.setState({ isLoading: false, error: errMsg, info: null });
            });

    }

    closeRequest(event)
    {
        event.preventDefault();
        this.setState({
            isRequestOpen: !this.state.isRequestOpen
        })
 
        var customerService = new CustomerService();
        customerService.CloseRequest(this.state.customerId, this.state.requestId)
            .then((resp) => {
               
                this.setState({ message: '', info:"This request is now closed.  You can open this request again by sending a message to the seller."});
                
                customerService.ReadRequest(this.state.customerId, this.state.requestId)
                    .then((resp) => {
                        console.log(resp.data);
                        this.setState({
                            request: resp.data
                        });
                });
                
            }).catch((err) => {
                console.log("err", err);

                var errMsg = err?.response?.data?.error;
                if(!errMsg)
                    errMsg = "Something went wrong. Please try again later."
                this.setState({ isLoading: false, error: errMsg, info: null });
            });

    }

    onInputChange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
    }

    requestTypeToString(type) {
        // eslint-disable-next-line default-case
        switch (type) {
            case 0:
                // Do something for summer
                break;
            case 1:
                return 'Cancel account';
            //Do something for winter
            case 2:
                return 'Refund payment'
            //Do something for spring
            case 3:
                return 'Cancel and refund'
            case 5:
                return 'Message'
        }
    }

 

    render() {
        this.requestMessage =  
            <>
                <p className='font-weight-bold'>If you’re happy with the response from the seller you can close the request.</p>
                <p className='font-weight-bold'>Alternatively, you can send them a response below </p>
            </>;
        this.closedRequestMessage = <p className='font-weight-bold'>This request is now closed.  You can open this request again by sending a message to the seller. </p>;
            

        return (
            <div className="bg-light">
                <section className="container mt-4">
                    <div>
                        <h4>Your conversation regarding {this.requestTypeToString(this.state.request.type)} request</h4>
                    </div>
                    {
                        React.Children.toArray(
                            
                            this.state.request.messages.map((msg) =>
                            msg.isMerchantResponse ?
                            <div className="d-flex flex-row justify-content-start mb-4" style={{backgroundColor:"white"}}>
                                <div className='p-2 ms-3 mb-1 p-3 rounded'> 
                                    <div style={{minHeight:40}}>
                                        <img src={this.state.request.productLogo} className="float-left" height={30} alt={this.state.productName} />
                                        <span className="ml-2 font-italic font-weight-bold  float-left">{msg.senderName} on {FormatHelper.JsonTimeToLocalTime(msg.createdOn)}</span>
                                    </div>
                                    
                                    <div dangerouslySetInnerHTML={{__html: msg.content}} className='mt-1'></div>
                                    
                                
                                </div>
                            </div>
                            :
                            <div className="d-flex flex-row justify-content-end mb-4 pt-1">
                                <div className='p-2 me-3 mb-1 p-3 text-white rounded bg-primary'>
                            
                                        <span className="font-italic font-weight-bold">You on {FormatHelper.JsonTimeToLocalTime(msg.createdOn)}</span>
                                        <pre className='text-white pre-wrapped'>{msg.content}</pre>
                                      
                                </div>
                            </div>
                            )
                        )
                    }

                    {this.state.request.status === 2 || this.state.request.status === 3 ? 
                    this.closedRequestMessage : this.requestMessage}

                    {this.state.error ?
                            <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                                <span>{this.state.error}</span>
                            </Alert> : null}
                    {this.state.info ?
                        <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                            <Alert.Heading>Thank you!</Alert.Heading>

                        </Alert> : null}
                        <form onSubmit={this.RequestContact}>
                            <div className="row mt-30">
               
                                <div className="col-12 mt-4">
                                    <textarea minLength="30" maxLength="500" name="message" required value={this.state.message} onChange={this.onInputChange} className="form-control" 
                                        placeholder="Your message."></textarea>
                                </div>

                                <div className="col-6 mt-4">
                                    <p className="text-white">
                                        <button type="submit" className="btn btn-darkblue btn-block">Send message</button>
                                    </p>
                                </div>
                                <div className="col-6 mt-4">
                                    <p className="text-white">
                                        <button type="button" onClick={this.closeRequest} disabled={this.state.request.status === 2 || this.state.request.status === 3} className="btn btn-warning btn-block">Close request</button>
                                    </p>
                                </div>
                            </div>
                        </form>
                    
                    <Link to={{pathname:`/requests/${this.state.customerId}`}} className='btn btn-block btn-primary mb-4 mt-4'>Back to requests</Link>
                </section>
            </div>

        );
    }
}

export default Messages;
