import React from 'react';
import CustomerService from '../service/CustomerService';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../index.css';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        //console.log('merchantName', this.state.merchantName);
        this.state =
        {
            merchantId: props.match.params.merchantId,
            productDescriptorId: props.match.params.productDescriptorId,
            customerId: props.match.params.customerId,

            merchantName:this.props.location.state.merchantName,
            info: null,
            error: null,
            name:'',
            message:''
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.RequestContact = this.RequestContact.bind(this);
        
    }

    RequestContact(event) {
        event.preventDefault();
        var customerService = new CustomerService();

        //this.setState({ isLoading: true });

        customerService.RequestContact(this.state.merchantId, this.state.productDescriptorId, this.state.customerId, this.state.message, this.state.name)
            .then((resp) => {
                console.log(resp.data)
                this.setState({ isLoading: false, refunded: true, info: "Your message has been sent successfully", error: null });
                
            }).catch((err) => {
                console.log("err", err);

                var errMsg = err?.response?.data?.error;
                if(!errMsg)
                    errMsg = "Something went wrong. Please try again later."
                this.setState({ isLoading: false, error: errMsg, info: null });
            });

    }

    onInputChange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div className="bg-light">
                <section className="container mt-4">
                    {!this.state.info && !this.state.error ?
                    <p>
                    Send a message directly to {this.state.merchantName} and get a response within 24 hours.
                    </p>
                    : null}

                    
                    {this.state.error ?
                            <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                                <p>
                                    {this.state.error}
                                </p>
                            </Alert> : null}
                    {this.state.info ?
                        <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                            <Alert.Heading>Thank you!</Alert.Heading>
                            <p>
                            Your message has been successfully sent and the {this.state.merchantName} will be in touch with you directly within 24 hours (Although please allow slightly longer on weekends and national holidays)
                            </p>
                            <p>
                                You can view your message, along with any responses from the retailer, at any time by viewing 
                                <Link to={{pathname:`/Requests/${this.state.customerId}`,
                                                       state:{customerRequests:this.state.customerRequests}}}> ​your requests</Link>
                            </p>                            
                        </Alert> : 
                        <form onSubmit={this.RequestContact}>
                            <div className="row mt-30">
                                <div className="col-12 mt-4">
                                    <input type="text" maxLength="50" name="name" className="form-control control-lg border-0" placeholder="Your Name" 
                                        value={this.state.name} onChange={this.onInputChange} />
                                </div>

                                <div className="col-12 mt-4">
                                    <textarea minLength="30" maxLength="500" name="message" required value={this.state.message} onChange={this.onInputChange} className="form-control" 
                                        placeholder="Your message."></textarea>
                                </div>

                                <div className="col-12 mt-4">

                                    <p className="text-white">
                                        <button type="submit" className="btn btn-darkblue btn-block">Send message</button>
                                    </p>
                                </div>
                                
                            </div>
                        </form>
                    }
                    
                    <button onClick={() => window.history.back()} className="btn btn-block btn-primary mb-4 mt-4">Back to home</button>
                </section>
            </div>

        );
    }
}

export default Contact;
