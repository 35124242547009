import React from 'react';
import NotFoundBanner from '../components/NotFoundBanner/NotFoundBanner'

class NotFound extends React.Component {


  render() {
    return (
      <NotFoundBanner />
    );
  }
}

export default NotFound;
