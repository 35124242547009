import React from 'react';
import { Alert } from 'react-bootstrap'
import CustomerService from '../service/CustomerService'
import HowItWorks from '../components/HowItWorks/HowItWorks';
import WhoAreWe from '../components/HowItWorks/WhoAreWe';
import CodeToDashboard from '../components/Home/CodeToDashboard';
import SearchByCard  from '../components/Home/SearchByCard';
import '../assets/css/Home.css';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.merchantCode = window.MERCHANT_CODE;

        if (this.merchantCode !== undefined) {
            var customerService = new CustomerService();
            customerService.RequestMerchant(this.merchantCode)
                .then((resp) => {
                    this.setState({ merchantId: resp.data.merchantId, productDescriptorId: resp.data.productDescriptorId, customerSearchType: resp.data.customerSearchType });
                }).catch((err) => {
                    this.setState({
                        error: err?.response?.data?.error ?? "There was an error while searching for the merchant. Please try again later."
                    });
                });
        }

        this.state =
        {
            email: '',
            code: '',
            error: null,
            info: null,
            customerSuccess: false,
            isLoading: false,
            merchantId: null,
            productDescriptorId: null,
            descriptorCode:'',
            customerSearchType:0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.SearchCustomer = this.SearchCustomer.bind(this);
        
        this.handleCodeChange = this.handleCodeChange.bind(this);
        
        this.handleDescriptorContinue = this.handleDescriptorContinue.bind(this);
        this.handleDescriptorInput = this.handleDescriptorInput.bind(this);
    }

    handleDescriptorContinue(event) {
        event.preventDefault();
        if(this.state.descriptorCode)
        {
            this.setState({ isLoading: true, error: null });
            var customerService = new CustomerService();
            customerService.RequestMerchant(this.state.descriptorCode)
                .then((resp) => {
                    // window.location.replace(window.location.href + this.state.descriptorCode); 
                    if(resp.data.merchantId){
                        this.setState({ merchantId: resp.data.merchantId, productDescriptorId: resp.data.productDescriptorId });
                        customerService.Search(this.state.merchantId, this.state.productDescriptorId, this.state.email)
                        .then(() => {
                            this.setState({
                                isLoading: false,
                                customerSuccess: true,
                                error: null,
                                info: "We've located the payments and are ready to help.  Please enter the verification code we've emailed to you to continue.",
                            });
                        }).catch((err) => {
                            this.setState({
                                customerSuccess: false,
                                isLoading: false,
                                error: "We didn't find any payments linked to that email address.  Please try searching with a different email address or send us a message and our support team will help you.", //err?.response?.data?.error ?? "We didn't find any payments linked to that email address.  Please try searching with a different email address or send us a message and our support team will help you.",
                                info: null
                            });
                        });
                    }                
                })

                .catch((err) => {
                    this.setState({
                        error:"We didnt find retailer with this code", //err?.response?.data?.error ?? "We didnt find retailer with this code",
                        isLoading: false,
                    });
                });
        }
    }

    handleDescriptorInput(event)  {
        this.setState({ descriptorCode: event.target.value });
    }

    handleChange(event) {
        //this.setState({ email: event.target.value });
        this.setState({
            [event.target.name]: event.target.value
          });
    }

    handleCodeChange(event) {
        this.setState({ code: event.target.value });
    }


    
    SearchCustomer(event) {
        event.preventDefault();
        var customerService = new CustomerService();

        this.setState({ isLoading: true });
        console.log(this.state.merchantId);
        customerService.Search(this.state.merchantId, this.state.productDescriptorId, this.state.email)
            .then(() => {
                this.setState({
                    isLoading: false,
                    customerSuccess: true,
                    error: null,
                    info: "We've found the payments. We've emailed you a code, please enter it here to prove you are the account holder."
                });
            }).catch((err) => {
                this.setState({
                    customerSuccess: false,
                    isLoading: false,
                    error: "We didn't find any payments linked to that email address.  Please try searching with a different email address or send us a message and our support team will help you.", //err?.response?.data?.error ?? "We didn't find any payments linked to that email address.  Please try searching with a different email address or send us a message and our support team will help you.",
                    info: null
                });
            });
    }


    render() {

        return (
            <div>
                <section className="overflow-hidden mainSection">
                    <div className="container mt-5">
                        <div className="row"> 
                            <div className="col-lg-7 align-items-center pr-lg-5 text-sm-left text-center">
                                <div>
                                    {this.merchantCode || this.state.customerSuccess ?
                                    null :
                                        <div className='mainContent'>
                                            <h1 className="display-5 headerText font-weight-bold col-lg-12 col-10 pl-0 ">
                                                We're here to <span>help</span>
                                            </h1>
                                            <p className="mt-4 fs-18 col-lg-12 col-11 pl-0">
                                            Sometimes things go wrong when you shop online. Mootpay helps you get your money back, resolve disputes and reverse fraud. 
                                            </p>
                                            <p className="helpText mt-4 fs-14 col-lg-12 col-11 pl-0 "><small>
                                            Every payment has a unique code. Enter your code below to get started.
                                                </small></p>
                                            <div className='row'>
                                                <div className='col-lg-7 col-9'>
                                                    <img src={require('../assets/img/Group-14.png')} className='img-fluid mb-2'  alt=''/>    
                                                </div>
                                            </div>

                                            
                                            
                                            <div>
                                                <form className="shadow-light" onSubmit={this.handleDescriptorContinue}>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='codeInput'>
                                                                <label htmlFor="inputEmail3" className="col-form-label nowrap">www.mootpay.com​/</label>
                                                                <input type="text"  id="inputEmail3" maxLength={6} onChange={this.handleDescriptorInput}  placeholder="Enter code" required/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='row bottomInput'>
                                                        <div className='col-lg-8 col-12 mt-2'>
                                                            <div className='emailBox'> 
                                                                <img src={require('../assets/img/@.png')} /> 
                                                                <input type="email" name="email" value={this.state.email} onChange={this.handleChange} required 
                                                                            className="form-control emailInput" placeholder="Enter email address here..." />
                                                            </div> 
                                                        </div>
                                                        <div className='col-lg-4 col-12'>
                                                            <button  type='submit' className='btn btn-block '>
                                                                        {this.state.isLoading ? 'Loading…' : <><span>Continue</span><i class="bi bi-chevron-right"></i></>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    }

                                    <div className="fx-fade-up animation-delay-4">
                                        {this.state.error ?
                                            <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                                                {/* <Alert.Heading>Oh no!</Alert.Heading> */}
                                                <p>{this.state.error}</p>
                                                <a href="/support" className="btn btn-sm btn-info">Contact support</a>
                                            </Alert> : null}
                                        {this.state.info ?
                                            <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                                                {/* <Alert.Heading>Success!</Alert.Heading> */}
                                                <p>{this.state.info}</p>
                                            </Alert> : null}
                                        <div className="">
                                            {this.merchantCode && !this.state.customerSuccess ?
                                                this.state.customerSearchType === 2 ?
                                                <SearchByCard productDescriptorId={this.state.productDescriptorId}></SearchByCard>
  
                                                :   
                                                <>
                                                    <div>
                                                        <h1 className="display-5 headerText font-weight-bold col-lg-12 col-10 pl-0">
                                                        We're here to <span>help</span>
                                                        </h1>
                                        
                                                        <p className="mt-4 fs-18 pl-0">
                                                            Sometimes things go wrong when you shop online. Mootpay helps you get your money back, resolve disputes and reverse fraud. 
                                                        </p>
                                                        <p className="mt-4">
                                                            If you have seen <b>MOOTPAY.COM</b> on your statement, enter your email address to see how we can help you:
                                                        </p>
                                                    </div>
                                                    <form className="ml-lg-auto shadow-light" onSubmit={this.SearchCustomer}>
                                                        <div className='row'>
                                                            <div className='col-sm-8'>
                                                                <input type="email" name="email" value={this.state.email} onChange={this.handleChange} required 
                                                                    className="form-control mt-2 emailForm" placeholder="Enter email address" />
                                                                <p className='small pl-2 pr-2'>*Please make sure you enter the email address you use for online payments.</p>
                                                            </div>
                                                            {/* <div className='small pl-2 pr-2'></div> */}
                                                            <div className='col-sm-4'>
                                                                <button className="btn-search text-white btn  mt-2" type="submit">
                                                                    {this.state.isLoading ? 'Loading…' : 'Search'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>

                                                : null
                                            }
                                            {this.state.customerSuccess ?
                                                <CodeToDashboard email={this.state.email}></CodeToDashboard>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 mt-lg-0 col-lg-5 p-lg-0">
                                <div className="bvw-lg-50 h-100 h-lg-100" >
                                    <img className='bg-img hero-phone' src={require('../assets/img/Group_11.png')} alt=''/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <HowItWorks />                
                <WhoAreWe />
                
            </div>

        );
    }
}

export default Home;
