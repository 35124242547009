import React from 'react';
import CustomerService from '../service/CustomerService';
import { Alert } from 'react-bootstrap'
import ContactMerchant from '../components/ContactMerchant/ContactMerchant'
import Payments from '../components/Payments/Payments';
import { Link } from 'react-router-dom';

class Dashboard2 extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state =
        {
            merchantId: props.match.params.merchantId,
            merchantCode: props.match.params.merchantCode,
            productDescriptorId: props.match.params.productDescriptorId,
            customerId: props.match.params.customerId,
            customerRequests: [],
            error: null,
            isLoaded: false,
            redirect: null,
            cancelAccountEnabled: false,
            ContactEnabled: false,
            RefundEnabled: false,
            RefundShowCommentBox: false,
            RefundShowReasonsList: false,
            merchantName:'?',
            optionsVisible: (window.localStorage.getItem('optionsVisible') || "false") === "true"
        };
        
    }

    componentDidMount()
    {
        // TODO: Get merchant data and page settings
        var customerService = new CustomerService();
        customerService.Dashboard(this.state.merchantId, this.state.productDescriptorId, this.state.customerId)
            .then((dashboardResp) => {
                //console.log(dashboardResp.data);
                this.setState({
                    cancelAccountEnabled: dashboardResp.data.cancelAccountEnabled,
                    contactEnabled: dashboardResp.data.contactEnabled,
                    refundEnabled: dashboardResp.data.refundEnabled,
                    refundShowCommentBox: dashboardResp.data.refundShowCommentBox,
                    refundShowReasonsList: dashboardResp.data.refundShowReasonsList,
                    merchantName: dashboardResp.data.companyName,
                    dashboardAvailable: true,
                    isLoaded: true,
                    customerRequests: dashboardResp.data.customerRequests,
                    productLogo: dashboardResp.data.productLogo,
                    productName: dashboardResp.data.productName,
                    productInfo: dashboardResp.data.productInfo,
                });
                
            }).catch((err) => this.handleError(err));

            this.CancelAccount = this.CancelAccount.bind(this);
        // customerService.CaseNumber(this.state.merchantId, this.state.customerId)
        //     .then(caseNoResp => {
        //         this.setState({
        //             caseNumber: caseNoResp.data
        //         });
        //     }).catch((err) => {
        //         this.setState({ isLoading: false, error: err?.response?.data?.error ?? "There was an issue retrieving the merchants contact information, please try again later." })
        //     });
    }

    handleError(err) {
        this.setState({
            isLoaded: true,
            error: err?.response?.data?.error
        });
    }

    showOptions(state)
    {
        this.setState({optionsVisible:state})
        window.localStorage.setItem('optionsVisible', 'true')
        window.scrollTo(0 ,0);
        //console.log("showOptions", state);
    }

    CancelAccount(event) {
        event.preventDefault();

        var customerService = new CustomerService();
        this.setState({ error: null, isLoading: true });
        customerService.CancelAccount(this.state.merchantId, this.state.productDescriptorId, this.state.customerId)
            .then((resp) => {
                var url = `/${this.state.merchantId}/${this.state.productDescriptorId}/${this.state.customerId}/Dashboard2/CancelAccount`;
                this.props.history.push(url, {productName:this.state.productName});
            }).catch((err) => {
                this.setState({ isLoading: false, error: err?.response?.data?.error })
                this.handleError(err);
            });
    }

    // TODO: Create components & render appropriately.
    render() {
        if (this.state.isLoaded) {
            return (
                <div className="bg-light">
                    <section className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex pr-lg-4">

                                {this.state.dashboardAvailable ?
                                    <div className="col-12 pt-2">
                                        {this.state.info ?
                                            <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                                                <Alert.Heading>Success!</Alert.Heading>
                                                <p>
                                                    {this.state.info}
                                                </p>
                                            </Alert> : null}
                                        {this.state.error ?
                                            <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                                                <p>
                                                    {this.state.error}
                                                </p>
                                            </Alert> : null}
                                        {this.state.optionsVisible ?
                                        <div>
                                            <center>
                                                <h3>What would you like to do next?</h3>
                                            </center>
                                            {this.state.cancelAccountEnabled ?
                                                        <button onClick={this.CancelAccount} className='btn btn-block btn-primary'>Cancel account</button>
                                                        //  <Link to={{pathname:`/${this.state.merchantId}/${this.state.productDescriptorId}/${this.state.customerId}/Dashboard2/CancelAccount`, 
                                                        //        state:{productName:this.state.productName}}} className='btn btn-block btn-primary'>Cancel account & stop payments</Link>
                                                        : null}
                                            {this.state.contactEnabled ?
                                                        <Link to={{pathname:`/${this.state.merchantId}/${this.state.productDescriptorId}/${this.state.customerId}/Dashboard2/Contact`, 
                                                               state:{
                                                                   merchantName: this.state.merchantName
                                                                }}} className='btn btn-block btn-primary'>Contact seller directly</Link>
                                                        : null}
                                            {this.state.refundEnabled ?
                                                        <a href={`/${this.state.merchantId}/${this.state.productDescriptorId}/${this.state.customerId}/Dashboard/Dispute`} className='btn btn-block btn-primary'>Dispute payments</a>
                                                        : null}
                                            <Link to={{pathname:`/${this.state.customerId}/Dashboard2/Requests`,
                                                       state:{customerRequests:this.state.customerRequests}}} className='btn btn-block btn-primary'>View my requests</Link>
                                        </div> :
                                        <div>
                                            <p>We've found your payments! Once you viewed the information below, you'll have a number of options including: </p>
                                            <ul>
                                            <li>Cancel your account</li>
                                            <li>Submit a complaint</li>
                                            <li>Request a refund</li>
                                            </ul>
                                        </div>
                                        }
                                       
                                        {/* <p>Please use this when talking to {this.state.merchantName ? <span>{this.state.merchantName}</span> : <span>the merchant</span>} so they can easily find you.</p> */}
                                            
                                        <h3 className="mt-2 text-center">Who charged me?</h3>
                                        
                                        <div className='row'>
                                            <div className='col-3'>
                                                <img src={this.state.productLogo} className="img-fluid" alt={this.state.productName} />
                                            </div>
                                            <div className='col-9'>
                                                <h3>{this.state.productName}</h3>
                                                <span className=''><small>{this.state.productInfo} </small></span>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <h3 className="text-center">Your payments</h3>
                                            <div className="">
                                            <Payments customerId={this.state.customerId} />
                                                
                                            </div>
                                        </div>
                                        {this.state.optionsVisible ? <span></span> :
                                        <div className="mt-5">
                                            <h3>Do you recognise this payments?</h3>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <button className='btn btn-block btn-primary' onClick={()=> this.showOptions(true)}>YES</button>
                                                </div>
                                                <div className='col-6'>
                                                    <button className='btn btn-block btn-primary' onClick={()=> this.showOptions(true)}>NO</button>
                                                </div>
                                            </div>
                                            <p></p>
                                        </div>
                                        }
                                    </div>
                                    : <div>
                                        <h2>Under Construction</h2>
                                        <p>Sorry, this page is currently unavailable. Please try again later, or contact the merchant directly.</p>
                                        <br />
                                        <ContactMerchant match={this.props.match} />
                                    </div>}
                            </div>
                        </div>
                    </section>
                </div>

            );
        }
        else {
            return (
                // TODO: Add loading icon
                <div></div>
            )
        }

    }
}

export default Dashboard2;


