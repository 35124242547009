import React from 'react';
import CustomerService from '../service/CustomerService';
import { Alert } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import '../index.css';

class Support extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            info: null,
            error: null,
            name:'',
            email:'',
            message:'',
            capchaStatus: false,
            isFormVisible: true,
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.RequestContact = this.RequestContact.bind(this);
        this.capchaHandler = this.capchaHandler.bind(this);
        
    }

    componentDidMount()
    {
        
    }

    RequestContact(event) {
        event.preventDefault();
        var customerService = new CustomerService();

        //this.setState({ isLoading: true });

        customerService.RequestSupport(this.state.name, this.state.email, this.state.message)
            .then((resp) => {
                console.log(resp.data)
                this.setState({ isLoading: false, refunded: true, info: "Your message has been sent successfully", error: null, isFormVisible: false });
                
                
            }).catch((err) => {
                console.log("err", err);

                var errMsg = err?.response?.data?.error;
                if(!errMsg)
                    errMsg = "Something went wrong. Please try again later."
                this.setState({ isLoading: false, error: errMsg, info: null });
            });
            
    }

    onInputChange(event) {
        this.setState({
          [event.target.name]: event.target.value
        });
    }
    capchaHandler() {
        this.setState({capchaStatus: true});
    }
    render() {
        return (
            <div className="bg-light min-vh-85 ">
                <section className="container mt-4 pt-md-10">
                    <center><h2>Contact us</h2></center>
                    <p>Please submit the form below if you have any questions, comments or suggestions regarding MootPay and we'll come back to you as soon as possible.</p>
                    {/* {!this.state.info && !this.state.error ?
                    <p>
                    Send a message directly to {this.state.merchant_name} and get a response within 24 hours.
                    </p>
                    : null} */}

                    
                    {this.state.error ?
                            <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                                <p>
                                    {this.state.error}
                                </p>
                            </Alert> : null}
                    {this.state.info ?
                        <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                            <Alert.Heading>Thank you!</Alert.Heading>
                            <p>
                                {this.state.info}
                            </p>
                        </Alert> : null}
                    {this.state.isFormVisible && 
                    <form onSubmit={this.RequestContact}>
                        <div className="row mt-30">
                            <div className="col-12 mt-4">
                                <input type="text" maxLength="50" name="name" required className="form-control control-lg border-0" placeholder="Your Name" 
                                        onChange={this.onInputChange} />
                            </div>
                            <div className="col-12 mt-4">
                                <input type="email" maxLength="90" name="email" required className="form-control control-lg border-0" placeholder="Your email" 
                                        onChange={this.onInputChange} />
                            </div>
                            <div className="col-12 mt-4">
                                <textarea minLength="10" maxLength="500" name="message" required onChange={this.onInputChange} className="form-control" 
                                    placeholder="Your message."></textarea>
                            </div>

                            <div className="col-12 mt-4">
                                <ReCAPTCHA
                                    sitekey={window.CAPCHA_SITE_KEY}
                                    onChange={this.capchaHandler}
                                />
                                <p className="text-white">
                                    {this.state.capchaStatus && <button type="submit" id="submitButton" className="btn btn-darkblue btn-block">Send message</button>}
                                </p>
                            </div>
                            
                        </div>
                    </form>}
                </section>
            </div>

        );
    }
}

export default Support;
