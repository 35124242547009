import React, {useState, useEffect} from 'react';
import { Alert } from 'react-bootstrap';
import CustomerService from '../../service/CustomerService';
import FormatHelper from '../../service/FormatHelper';
import LoadingSpinner from '../Animations/LoadingSpinner';

const PaymentsNew = ({customerId, onPaymentSelect, select}) => {

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [noPaymentsToDisputeAlert, setNoPaymentsToDisputeAlert] = useState(false);

  select = select ?? false;

  const customerService = new CustomerService();

  useEffect(()=>{
    customerService.GetPayments(customerId)
    .then((resp) => {
        const {customerPayments} = resp.data;
        let succededStatusCount = 0;
        for(let index = 0; index < customerPayments.length; index++){
            if(customerPayments[index].status === 'succeeded'){
                succededStatusCount++;
            }
        }
        setIsLoading(false);
        setPayments(customerPayments);
        setNoPaymentsToDisputeAlert(succededStatusCount === 0);
    })
    .catch((err) =>{
        setIsLoading(false);
        setError(err?.response?.data?.error);
    });
  }, [ customerId])


    const handlePaymentSelect = (event) => {
 
        for(let i=0;i< payments.length;i++) // update array
        {
            if(payments[i].id === event.target.value)
                payments[i].checked = event.target.checked;
        }

        let selectedPayments=[];
        for(let j=0;j<payments.length;j++) // build selected attay
        {
            if(payments[j].checked)
            selectedPayments.push(payments[j].id);
        }

        onPaymentSelect(selectedPayments);
        }

  return (
    <div>
        {error ?
        <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
            <Alert.Heading>Oh no! An error occurred!</Alert.Heading>
            <span>{error}</span>
        </Alert> : null}
        <div className="card h-100 shadow-light">
            <div className="card-body">
                {select && noPaymentsToDisputeAlert ? <h3>There are no transactions that can be disputed</h3> : null }
        
                <table className="table table-sm" style={{ position: "relative", height: "100px", overflowY: "auto" }}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            {select ? <th>Select</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? 
                        <tr>
                            <td colSpan="100%" className='text-center pt-4'><LoadingSpinner/></td>
                        </tr> :                  
                            React.Children.toArray(
                                payments.map((payment) =>
                                    <tr>
                                        <td><span title={payment.timestamp}>{FormatHelper.JsonTimeToLocalDate(payment.timestamp)}</span></td>
                                        <td>{FormatHelper.formatCurr(payment.amount.toFixed(2),payment.currency)}</td>
                                        <td>{payment.status}</td>
                                        {select ? 
                                        <td>
                                            <input type={'checkbox'} onChange={handlePaymentSelect} value={payment.id} disabled={payment.status!=='succeeded'}></input> 
                                        </td>: null}
                                    </tr>
                                )
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default PaymentsNew