import React from 'react';

class HowItWorks extends React.Component {
    render() {
        return (
        <section className="bg-white pt-4 pt-lg-6 pb-0">
                <h3 className="text-ph-blue font-nunito text-uppercase text-center fs-13">
                    <strong>MootPay</strong>
                </h3>
                <h2 className="text-darkblue text-center display-5">
                    How it works
                </h2>
                <p className="text-center w-lg-700 ml-auto mr-auto mt-5 text-lg">
                    MootPay understands that sometimes things go wrong when you shop online. That's why we're trusted by thousands of retailers to give you quick access to your purchase information, as well as the tools necessary to manage payments and resolve any disputes instantly, allowing you to shop online with confidence.
                </p>
                <div className="row mt-7">
                    <div className="col-md-4 text-center bg-darkblue right-arrow text-white">
                        <div className="p-3 pt-5">
                            <div className="svg-icon svg-icon-x1" >
                                <img src={require('../../assets/img/i-1x1.png')} alt="" srcSet="" />
                            </div>
                            <h4 className="mt-4">
                                Enter email address
                            </h4>
                            <p>
                                MootPay works with retailers to instantly identify payments & charges
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 text-center bg-purple text-white mt-md-0 right-arrow">
                        <div className="p-3 pt-5">
                            <div className="svg-icon svg-icon-xl">
                                <img src={require('../../assets/img/i-2x1.png')} alt="" srcSet="" />
                            </div>
                            <h4 className="mt-4">
                                Let MootPay do the work
                            </h4>
                            <p>
                                MootPay analyses your payments and provides information and tools for any next steps
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 text-center bg-ph-blue text-white mt-md-0">
                        <div className="p-3 pt-5">
                            <div className="svg-icon svg-icon-xl">
                                <img src={require('../../assets/img/i-3x1.png')} alt="" srcSet="" />
                            </div>
                            <h4 className="mt-4">
                                Problem solved
                            </h4>
                            <p>
                                Complaints about a purchase? Fraudulent activity? MootPay resolves all issues within 24 hours
                            </p>
                        </div>
                    </div>
                </div>
        </section>
        )
    }
}

export default HowItWorks;
