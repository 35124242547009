import React from 'react';
import { Button, Alert } from 'react-bootstrap'
import CustomerService from '../../service/CustomerService'


class CancelAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            merchantId: props.match.params.merchantId,
            merchantCode: props.match.params.merchantCode,
            customerId: props.match.params.customerId,
            productDescriptorId: props.match.params.productDescriptorId,
            error: null,
            isLoaded: false,
            redirect: null,
            accountCancelled: false,
            info: null,
            isLoading: false
        };

        this.CancelAccount = this.CancelAccount.bind(this);
    }

    CancelAccount(event) {
        event.preventDefault();
        var customerService = new CustomerService();
        this.setState({ error: null, isLoading: true });
        customerService.CancelAccount(this.state.merchantId, this.state.productDescriptorId, this.state.customerId)
            .then((resp) => {
                console.log(resp.data)
                this.setState({ isLoading: false, accountCancelled: true, info: "Your account has been cancelled." })
            }).catch((err) => {
                this.setState({ isLoading: false, error: err?.response?.data?.error })
            });
    }

    render() {
        return (
            <div>
                {this.state.info ?
                    <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>
                            {this.state.info}
                        </p>
                    </Alert> : null}
                {this.state.error ?
                    <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                        <Alert.Heading>Oh no! An error occurred!</Alert.Heading>
                        <p>
                            {this.state.error}
                        </p>
                    </Alert> : null}
                <div className="card h-100 shadow-light">
                    <div className="card-body">
                        <h5 className="font-weight-normal text-dark text-decoration-none">
                            Cancel
                        </h5>
                        <p className="text-secondary font-size-sm mt-3">
                            Click here if you would like PaymentHelp to cancel your account on your behalf.
                        </p>
                    </div>
                    <div className="card-bottom-info">
                        <form onSubmit={this.CancelAccount}>
                            <Button block type="submit" className="btn btn-danger hover-lift-light" disabled={this.state.accountCancelled}>  {this.state.isLoading ? 'Loading…' : 'Cancel account'}</Button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default CancelAccount;
