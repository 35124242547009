import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch  } from 'react-router-dom'


import './assets/css/styles.css'

// import all vendor scripts - to do , gulp task to merge to vendor css
import "./assets/vendor/fancybox/jquery.fancybox.css"
import "./assets/vendor/pikaday/pikaday.css"
import "./assets/vendor/jarallax/dist/jarallax.css"
import "./assets/vendor/highlight/styles.css"
import "./assets/vendor/swiper/swiper.css"
import "./assets/vendor/atvImg/atvImg.css"


// Pages
import Home from './page/Home';
import Dashboard from './page/Dashboard';
import Dispute from './page/Dispute';
import NotFound from './page/NotFound'
import Dashboard2 from './page/Dashboard2';
// Components
import Navbar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'
import CookiePolicy from './page/CookiePolicy';
import PrivacyPolicy from './page/PrivacyPolicy';
import TermsAndConditions from './page/TermsAndConditions';
import Contact from './page/Contact';
import CancelAccount from './page/CancelAccount.jsx';
import Requests from './page/Requests';
import Support from './page/Support';
import Messages from './page/Messages';
import Dashboard3 from './page/Dashboard3';
import Dispute2 from './page/Dispute2';


const routing = (
  <Router>
    
    <Navbar/>
    <Switch>
      <Route exact path="/" component={Home} /> 
      <Route exact path="/cookie-policy" component={CookiePolicy} /> 
      <Route exact path="/privacy-policy" component={PrivacyPolicy} /> 
      <Route exact path="/terms-conditions" component={TermsAndConditions} /> 
      <Route exact path="/support" component={Support} />
      <Route exact path="/:merchantCode" component={Home}  />
      <Route exact path="/:merchantId/:productDescriptorId/:customerId/Dashboard" component={Dashboard} />
      <Route exact path="/:merchantId/:productDescriptorId/:customerId/Dashboard/Dispute" component={Dispute} />
      <Route exact path="/:merchantId/:productDescriptorId/:customerId/Dashboard2" component={Dashboard2} />
      <Route exact path="/:merchantId/:productDescriptorId/:customerId/Dashboard2/Contact" component={Contact} />
      <Route exact path="/:merchantId/:productDescriptorId/:customerId/Dashboard2/CancelAccount" component={CancelAccount} />
      <Route exact path="/:customerId/Dashboard2/Requests" component={Requests} />
      <Route exact path="/Messages/:customerId/:requestId" component={Messages} />
      <Route exact path="/panel/:customerId" component={Dashboard3} />
      <Route exact path="/requests/:customerId" component={Requests} />
      <Route exact path="/contact/:customerId" component={Contact} />
      <Route exact path="/dispute/:customerId" component={Dispute2} />
      <Route exact path="/cancel/:customerId" component={CancelAccount} />

      <Route exact path='*' component={NotFound} />
    </Switch>
    <Footer/>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root')); 