import React from 'react';
import CustomerService from '../service/CustomerService';
import { Alert } from 'react-bootstrap'
import RateApp from '../components/RateApp/RateApp'

class CancelAccount extends React.Component {
    constructor(props) {
        super(props);
        
        this.state =
        {
            customerId: props.match.params.customerId,
            success:false            
        };
        this.CancelAccount = this.CancelAccount.bind(this);
    }

    CancelAccount(event) {
        event.preventDefault();

        var customerService = new CustomerService();
        this.setState({ error: null, isLoading: true });
        customerService.CancelAccount(this.state.merchantId, this.state.productDescriptorId, this.state.customerId)
            .then((resp) => {
                this.setState({success:true});
            }).catch((err) => {
                this.setState({ error: err?.response?.data?.error })
            });
    }

    render() {
        if (this.state.success) {
            return (
                <div className="bg-light">
                    <section className="container pt-3 text-center">
                        
                        <h2 className="my-2">
                            Success
                        </h2>
                        <p className="mt-4">
                            We have accessed the sellers system and can confirm that your account with them has now been cancelled. 
                            This means that no further payments will be made to {this.props.location.state.productName}
                        </p>
                        <p className="mt-4">
                            Please take a moment to tell us how satisfied you are with the help you receive from MootPay:
                        </p>

                        <RateApp source="CancelAccount" customerId="customerId1"></RateApp>
                     
                        <button onClick={() => window.history.back()} className="btn btn-block btn-primary mb-4 mt-2">Back to home</button>
                        
                    </section>
                </div>

            );
    }
    else {
        return (
                <div className="bg-light">
                    <section className="container pt-3 text-center">
                        
                        <h2 className="my-2">
                            Cancel your account
                        </h2>
                        <div style={{minHeight:"60px"}}>
                        {this.state.error ?
                            <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                      
                                    {this.state.error}
                               
                            </Alert> : null}
                        </div>

                        <p>
                            If you are in a subscription, we can cancel that for you
                        </p>
                        <p className="mt-4">
                            Please click on the button below to confirm your cancellation request.
                        </p>
                        <p className="mt-6">
                            <button type="submit" onClick={this.CancelAccount} className="btn btn-darkblue btn-block">Cancel my account</button>    
                        </p>
                        
                        
                        <button onClick={() => window.history.back()} className="btn btn-block btn-primary mb-4 mt-2">Back to home</button>
                        
                    </section>
                </div>
        )
    }
    }
}

export default CancelAccount;
