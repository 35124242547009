import React, {useState} from 'react';
import './NavBar.css';

function NavBar() {
    const [mouseEnterText, setMouseEnterText] = useState('Merchant');

    const WIDTH = window.innerWidth;
    console.log(WIDTH);
    const handleMouseOver = ()=>{
        if(WIDTH > 765){
            setMouseEnterText('Click Here For Merchant') 
        }
        
    }
    const handleMouseLeave =()=>{
        setMouseEnterText('Merchant')
    }

return (
    <nav className="navbar navbar-expand-md bg-white navbar-padding " role="navigation"> 
    <div className="container position-relative">
        <a className="navbar-brand" href="/"> 
            <img src={require('../../assets/img/Mootpay_Blue.png')} srcSet="" height="36"  alt="MootPay" className='logo'/>
            <img src={require('../../assets/img/RectangleNav.png')} className='sepRectangle'/>
        <p className='motto'>shop with confidence</p>
        </a>


        {/* <div className="merchant">
            <a className="nav-link" href="https://merchant.mootpay.com/" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}> 
                <img src={require('../../assets/img/caseMerchant.png')}/> 
                <p className='merchantButton'>{mouseEnterText}<span> Portal</span></p>
            </a>
        </div>  */}

    </div> 
    </nav>
    )
}

export default NavBar;
