import React from 'react';
import { Alert } from 'react-bootstrap'
import CustomerService from '../../service/CustomerService'
import FormatHelper from '../../service/FormatHelper'
import CodeToDashboard from './CodeToDashboard';
import MultiTransactionCard from './MultiTransactionCard';
import SingleTransactionCard from './SingleTransactionCard';
import '../../index.css'
import './SearchByCard.css';

class SearchByCard extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {     
            productDescriptorId:this.props.productDescriptorId,
            last4:"",
            amount:"",
            cardData:null,
            chargeId:null,
            email:"",
            // expM:null,
            // expY:null,
            isLoading:false,
            chargesLength: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.SearchCard = this.SearchCard.bind(this);
        this.SelectTransaction = this.SelectTransaction.bind(this);
        

    }
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
          });
    }

    SearchCard(event) {
        event.preventDefault();
        var customerService = new CustomerService();

        this.setState({ isLoading: true, error:null });

    customerService
      .SearchCard({
        last4digits: this.state.last4,
        amount: this.state.amount,
        productDescriptorId: this.state.productDescriptorId,
        expMonth: this.state.expM,
        expYear: this.state.expY,
        email: this.state.email,
        chargeId: this.state.chargeId,
      })
      .then((d) => {
        this.setState({
          isLoading: false,
          customerId: d.data.customerId,
          charges: d.data.charges,
          chargesLength: d.data.charges.length,
        });
        console.log("cardData", d.data);
 

      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          error: `Unfortunately we didn't locate any transactions using those details.  Please double check and submit again.`,
        });
      });

      console.log("charges", this.state.charges);
  }


    SelectTransaction(chId) {
        this.setState({
            chargeId: chId
        });
        console.log('chId', chId);
    }

    render() {
        if(this.state.customerId)
            return (  
                <CodeToDashboard email={this.state.email}></CodeToDashboard>
            );

    if (this.state.chargeId || this.state.chargesLength === 1){
        //after click on transaction or only for one transaction

        return (
            <div>
                {this.state.chargesLength === 1 && <SingleTransactionCard charges={this.state.charges} chargeId={this.state.chargeId}/>}
                {this.state.chargesLength > 1 && <MultiTransactionCard charges={this.state.charges} chargeId={this.state.chargeId}/>}

            {this.state.error ? (
                <Alert
                className='mt-4'
                variant="danger"
                onClose={() => this.setState({ error: null })}
                dismissible
                >
                <span>{this.state.error}</span>
                <br />
                <span>
                    Alternatively you can constact us
                    <a href="https://mootpay.com/support"> here</a>
                </span>
                </Alert>
            ) : null}




            <form className="ml-lg-auto shadow-light" onSubmit={this.SearchCard}>
                <div className="row">
                <div className="col-sm-8">
                    <p className="text-white mt-4 ">
                    For security, please confirm you are the cardholder by
                    confirming the card expiry date
                    </p>
                    <div className='exp-wrapper form-control mt-2'>
                        <input
                        type="text"
                        name="expM"
                        maxLength={2}
                        value={this.state.expM}
                        onChange={this.handleChange}
                        required
                        className="exp mt-2"
                        placeholder="MM"
                        />
                        <input
                        type="text"
                        name="expY"
                        maxLength={2}
                        value={this.state.expY}
                        onChange={this.handleChange}
                        required
                        className="exp mt-2"
                        placeholder="YY"
                        />

                    </div>

                    <input 
                    type="email" 
                    name="email" 
                    value={this.state.email} 
                    onChange={this.handleChange} 
                    required 
                    className="form-control mt-2" 
                    placeholder="Your email address" 
                    />

                </div>
                <div className="col-sm-4 mt-auto mb-0">
                    <button
                    className="btn btn-purple btn-block btn-lg hover-lift-light form-control mt-2"
                    type="submit"
                    >
                    Submit
                    </button>
                </div>
                </div>
            </form>
            </div>
        );
    }


    if (this.state.charges && this.state.chargesLength > 1){
        console.log("chargesLength", this.state.chargesLength);
        return (
        
            <>
                <div>
                    <h1 className="display-5 text-white">
                        We're locating the transaction
                    </h1>
                </div>
                <div className="ml-lg-auto shadow-light mt-4">
                    <p className="text-white">
                        We’ve located multiple potential transactions.
                        Please select the correct transaction from the list below:
                    </p>
                    <ul className="list-group">
                        <div className="list-group-item d-flex justify-content-between font-weight-bold">
                        <span>Date</span>
                        <span>Purchase</span>
                        <span>Card issuer</span>
                        <span>Amount</span>
                        <span></span>
                        </div>
                        {React.Children.toArray(
                        this.state.charges.map((ch) => (
                            <button
                            type="button"
                            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                            title="{ch.id}"
                            onClick={() => this.SelectTransaction(ch.id)}
                            >
                            <span>
                                {FormatHelper.JsonTimeToLocalDate(ch.transactionDate)}
                            </span>
                            <span>{ch.sellerName}</span>
                            <span>{ch.cardIssuer}</span>
                            <span>{ch.amount}</span>
                            <span>
                                <i className="bi-caret-right-fill"></i>
                            </span>
                            </button>
                        ))
                        )}
                    </ul>
                </div>
            </>
            
          );
    } 

    
    return (
      <div>
        <>
            <div>
                <h1 className="display-5 text-white">
                Don't recognize a payment? Just ask MootPay
                </h1>

                <p className="text-white mt-4">
                    At MootPay we're experts in consumer rights, helping you manage payments, subscriptions and fraudulent activity. 98% of all issues are solved within 24hrs.
                </p>
                <p className="text-white mt-4 ">
                    If you have seen <b>MOOTPAY.COM</b> on your statement, enter the transaction details below and see how we can help you:
                </p>
            </div>
            
                {this.state.error ? (
                <Alert
                    variant="danger"
                    onClose={() => this.setState({ error: null })}
                    dismissible
                >
                    <span>{this.state.error}</span>
                    <br />
                    <span>
                    Alternatively you can constact us
                    <a href="https://mootpay.com/support"> here</a>
                    </span>
                </Alert>
            ) : null}

            <form className="ml-lg-auto shadow-light" onSubmit={this.SearchCard}>
            <div className="row">
                <div className="col-sm-8">
                <p className="text-white mt-2">
                    Transaction amount
                </p>
                <input
                    type="text"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleChange}
                    required
                    className="form-control mt-2"
                    placeholder="Enter transaction amount"
                />
                <p className="text-white mt-4 ">
                    Last 4 digits of payment card used
                </p>
                <input
                    type="text"
                    name="last4"
                    maxLength={4}
                    value={this.state.last4}
                    onChange={this.handleChange}
                    required
                    className="form-control mt-2 bottomSep"
                    placeholder="Enter last 4 digits"
                />
                </div>
                <div className="col-sm-4 mt-auto mb-0">
                <button
                    className="btn btn-purple btn-block btn-lg hover-lift-light form-control mt-2"
                    type="submit"

                >
                    Next
                </button>
                </div>
            </div>
            </form>
            
        </>
      </div>
    );
  }
}

export default SearchByCard;
