import React from 'react';
import { Button, Alert } from 'react-bootstrap'

class Refund extends React.Component {
    constructor(props) {
        super(props);

        this.state =
        {
            merchantId: props.match.params.merchantId,
            merchantCode: props.match.params.merchantCode,
            productDescriptorId: props.match.params.productDescriptorId,
            customerId: props.match.params.customerId,
            error: null,
            isLoaded: false,
            redirect: null,
            refunded: false,
            isLoading: false,
            caseNumber: props.caseNumber
        };
    }

    render() {
        return (
            <div>
                {this.state.info ?
                    <Alert variant="info" onClose={() => this.setState({ info: null })} dismissible>
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>
                            {this.state.info}
                        </p>
                    </Alert> : null}
                {this.state.error ?
                    <Alert variant="danger" onClose={() => this.setState({ error: null })} dismissible>
                        <Alert.Heading>Oh no! An error occurred!</Alert.Heading>
                        <p>
                            {this.state.error}
                        </p>
                    </Alert> : null}
                <div className="card h-100 shadow-light">
                    <div className="card-body">
                        <h5 className="font-weight-normal text-dark text-decoration-none">
                            Refund
                    </h5>
                        <p className="text-secondary font-size-sm mt-3">
                        
                            Click here if you believe the payment was taken incorrectly and would like to request a refund.
                        </p>
                    </div>
                    <div className="card-bottom-info">
                        <form>
                            <Button block type="submit" className="btn btn-warning hover-lift-light" disabled={this.state.refunded} href={`/${this.state.merchantId}/${this.state.productDescriptorId}/${this.state.customerId}/Dashboard/Dispute`}>{this.state.isLoading ? 'Loading…' : 'Request refund'}</Button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Refund;
